<!--
One Version as single line component
-->
<template>
  <div class="ctrlVersionSmall">
    <span title="open" class="ctrlVersionSmallLink" v-on:click="click">&#x1F4C2;</span>
    <span class="ctrlVersionSmallTitle" :title="tooltip">{{versionTitle}}</span>
    <span v-if="versionId" class="ctrlVersionSmallId" @click="id2clipboard" title="Version ID in Zwischenablage kopieren">
      V-ID:{{versionId}}
    </span>
  </div>    
</template>
<style>
    span.ctrlVersionSmallTitle {
        font-size: 1em;
    }
    span.ctrlVersionSmallSubTitle {
        margin-left: 10px;
        font-size: 0.7em;
        font-weight: 700;
    }
    span.ctrlVersionSmallDate {
        margin-left: 10px;
        font-size: 0.7em;
        color: #444444;
    }
    span.ctrlVersionSmallLink {
        cursor: pointer;
        color: #333399;
        padding-right:5px;
    }
    span.ctrlVersionSmallId {
        margin-left: 5px;
        font-weight: 700;
        font-size: 0.8em;
        color: #777777;
        cursor: pointer;
    }
</style>
<script>

import SrvUiHelper from '../global/SrvUiHelper';
import SrvFormatHelper from '../global/SrvFormatHelper';

export default {
    props: [
        'config', // {  onclick:function } to be invoced by a click
        'id',     // ID of event (record data are reloaded from server)
        'record'  // {version-id:...}   full record as alternative to id.
    ],
    // $event(click, versionId)
    data: function() {
        return  {
            theRecord: this.record
        };
    },
    computed: {
        versionId: function() {
            if (this.id)
                return this.id;
            return this.theRecord ? this.theRecord.version_id : '';
        },
        versionTitle: function() {
            if (!this.theRecord)
                return '';
            const genericVersionTitle = 'Version '
                + this.theRecord['version-id']
                + (this.theRecord.version_publication ? ' '+this.theRecord.version_publication : '');
            let versionTitle = this.theRecord.version_title || genericVersionTitle;
            if (this.theRecord.version_lang) versionTitle += ' ('+this.theRecord.version_lang+')';
            if (this.theRecord.version_default) versionTitle += ' (DEFAULT)';
            return versionTitle;
        },
        tooltip: function() {
            if (!this.theRecord)
                return '';
            return (this.theRecord.version_description ? this.theRecord.version_description : '')
                  +(this.theRecord.version_default     ? this.theRecord.version_default     : '');
        }
    },
    watch: {
        record: function(newVal) {
            this.theRecord = newVal;
        }
    },
    mounted: function() {
        if (this.id  && !this.record)
            this.loadData();
    },
    methods: {
        loadData: function() {
            if (!this.id)
                return;
            const that = this;
            SrvServerAccess.request('POST','/api/versionGet', {version_id: this.id}).then(
                function(serverData) {
                    that.theRecord = serverData.data;
                },
                function(error) {
console.error(error);
                }
            );
        },
        click: function() {
            if (this.config && this.config.onclick)
                this.config.onclick();
            this.$emit('click', this.versionId);
        },
        id2clipboard: function(event) {
            SrvUiHelper.copyValueToClipboard(event, this.versionId);
        }
    }
}
</script>
