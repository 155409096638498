<!--
One Event as single line component
-->
<template>
  <div class="ctrlEventSmall">
    <span title="open" class="ctrlEventSmallLink" v-on:click="$emit('click', eventId)">&#x1F4C6;</span>
    <span class="ctrlEventSmallTitle">{{eventTitle}}</span>
    <span class="ctrlEventSmallSubTitle">{{eventSubTitle}}</span>
    <span title="event date" class="ctrlEventSmallDate">{{eventDate}}</span>
    <span v-if="eventId" class="ctrlEventSmallId" @click="id2clipboard" title="Event ID in Zwischenablage kopieren">
      E-ID:{{eventId}}
    </span>
  </div>    
</template>
<style>
    span.ctrlEventSmallTitle {
        font-size: 1em;
        font-weight: 700;
    }
    span.ctrlEventSmallSubTitle {
        margin-left: 10px;
        font-size: 0.7em;
        /*
        idea: event: 0.7   text: 1.0
        but in practice: the event title is verbous, the text title is nothing
        */
        font-weight: 700;
    }
    span.ctrlEventSmallDate {
        margin-left: 10px;
        font-size: 0.7em;
        color: #444444;
    }
    span.ctrlEventSmallLink {
        cursor: pointer;
        color: #333399;
        padding-right:5px;
    }
    span.ctrlEventSmallId {
        margin-left: 5px;
        font-weight: 700;
        font-size: 0.8em;
        color: #777777;
        cursor: pointer;
    }
</style>
<script>

import SrvServerAccess from '../global/SrvServerAccess';
import SrvUiHelper from '../global/SrvUiHelper';
import SrvFormatHelper from '../global/SrvFormatHelper';

export default {
    // name: 'ctrl-event-small',   ---> dann in main.js   import CmpCtrlEventSmall from....; Vue.component('ctrl-event-small', CmpCtrlEventSmall);
    props: [
        'id',     // ID of event (record data are reloaded from server)
        'record', // {event-id:...}   full record as alternative to id.
        'onclick' // function to be invoced by a click
    ],
    // $event(click, eventId)
    data: function() {
        return  {
            theRecord: this.record
        };
    },
    computed: {
        eventId: function() {
            return this.id
                || (this.record    ? this.record.event_id    : '')
                || (this.theRecord ? this.theRecord.event_id : '');
        },
        eventDate: function() {
            if (!this.theRecord)
                return '';
            let evtDate = SrvFormatHelper.dateSql2deutsch(this.theRecord.event_start_date);
            if (this.theRecord.event_end_date!=this.theRecord.event_start_date)
                evtDate += ' - '+SrvFormatHelper.dateSql2deutsch(this.theRecord.event_end_date);
            if (this.theRecord.event_accuracy)
                evtDate += ' (Genauigkeit: '+this.theRecord.event_accuracy+')';
            return evtDate;
        },
        eventTitle: function() {
            if (!this.theRecord)
                return this.id;
            return this.getBestTranslation('event_transl_title');
        },
        eventSubTitle: function() {
            // return 'kein Subtitel';
            if (!this.theRecord)
                return this.id;
            return this.getBestTranslation('event_transl_sub_title');
        }
    },
    watch: {
        record: function(newVal) {
            this.theRecord = newVal;
            if (this.record && !this.record.event_start_date && !this.record.event_end_date && !this.record.event_accuracy && !this.record.event_translation)
                this.loadData();
        }
    },
    mounted: function() {
        if (this.id && !this.record)
            this.loadData();
        else if (this.record && !this.record.event_start_date && !this.record.event_end_date && !this.record.event_accuracy && !this.record.event_translation)
            this.loadData();
    },
    methods: {
        getBestTranslation: function(key) {
            // event_translation: [{
            //     event_transl_abbrev: "Vortr (Nov) 12"
            //     event_transl_admin_keywords: "google transl."
            //     event_transl_create_datetime: "2012-08-30T00:00:00.000000Z"
            //     event_transl_event_id: 6
            //     event_transl_id: 6006
            //     event_transl_lang: "spa"
            //     event_transl_notes: null
            //     event_transl_subtitle: null
            //     event_transl_title: "María, Reina de los Apóstoles. Conferencia de Limburgo"
            //     event_transl_update_datetime: "2013-05-07T17:25:19.000000Z"
            // }]
            if (!this.theRecord || !this.theRecord.event_translation)
                return '';
            const translations = this.theRecord.event_translation;
            let otherLanguage = '';
            for (let i=0; i<translations.length; i++) {
                const item = translations[i];
                if (!item[key]) continue;
                if (item.event_transl_lang=='deu')
                    return item[key];
                else if (!otherLanguage)
                    otherLanguage = item[key];
            }
            return otherLanguage;
        },
        loadData: function() {
            if (!this.eventId)
                return;
            const that = this;
            SrvServerAccess.request('POST','/api/eventGet', {event_id: this.eventId}).then(
                function(serverData) {
                    that.theRecord = serverData.data.event[0];
                },
                function(error) {
console.error(error);
                }
            );
        },
        id2clipboard: function(event) {
            SrvUiHelper.copyValueToClipboard(event, this.eventId);
        }
    }
}
</script>
