<!--
All data of a medium (=text) as a huge control element in order to be displayed on any view
-->
<template>
  <div class="ctrlMedium">
    <!-- hacky hacky: the link button makes no sense within the context of CmpMedium, but in this case it is hidden by the menu bar -->
    <div v-if="showLinkButton" class="ctrlMediumLinkBtn" title="Direct link to this medium"><a target="link" :href="directLink">&#x1F517;</a></div>
    <div class="ctrlMediumEditBtn" v-dir-permission="'g_EDITORS'" v-on:click="$emit('edit-medium',mediumId)" title="Edit Metadata for this Medium">&#x270e;</div>
    <div class="ctrlMediumTitleFrame">
        <div class="ctrlMediumTitle">
            <h1>{{mediumData.medium_title || 'kein Titel'}}</h1>
        </div>
    </div>
    <div class="ctrlMediumToolbar">
        <span title="show metadata" class="ctrlMediumToolbarLink" v-on:click="toggleMetadata()">&#x1F4AC;</span>
        <span v-dir-permission="'g_EDITORS'" v-if="!editMode" title="edit" class="ctrlMediumToolbarLink" v-on:click="setEditMode(true)">&#x1F589;</span>
        <span v-if="editMode" title="cancel changes" class="ctrlMediumToolbarLink" v-on:click="setEditMode(false,false)">&#x26CC;</span>
        <span v-if="editMode" title="save" class="ctrlMediumToolbarLink" v-on:click="setEditMode(false,true)">&#x1F4BE; </span>
        <span v-if="!editMode" title="toggle page numbers" class="ctrlMediumToolbarLink" v-on:click="togglePageNr()" :style="{opacity:showPageNr?1:0.4}">&#x1F522;</span>
    </div>
    <Transition>
        <div v-if="metadataExpanded" class="searchResultBlockDetails">
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">ID:</span> <span class="ctrlMediumValue">{{mediumData.medium_id}}</span>
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Event-ID:</span>
                <span v-if="!editMode" class="ctrlMediumValue">{{mediumData.medium_event_id}}</span>
                <input v-if="editMode" class="ctrlMediumValue" v-model="mediumData.medium_event_id" title="Bitte nur ändern, wenn Du genau weißt, was du tust">
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Version-ID:</span>
                <span v-if="!editMode" class="ctrlMediumValue">{{mediumData.medium_version_id}}</span>
                <input v-if="editMode" class="ctrlMediumValue" v-model="mediumData.medium_version_id" title="Bitte nur ändern, wenn Du genau weißt, was du tust">
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Titel:</span>
                <span v-if="!editMode" class="ctrlMediumValue">{{mediumData.medium_title}}</span>
                <input v-if="editMode" class="ctrlMediumValue" v-model="mediumData.medium_title">
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Datum:</span> <span class="ctrlMediumValue">{{mediumData.medium_date_path}}</span>
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Datei:</span>
                <span v-if="!editMode" class="ctrlMediumValue">{{mediumData.medium_source_file}}</span>
                <input v-if="editMode" class="ctrlMediumValue" v-model="mediumData.medium_source_file">
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Dateidatum:</span>
                <span v-if="!editMode" class="ctrlMediumValue">{{mediumData.medium_source_file_date_modified}}</span>
                <input v-if="editMode" class="ctrlMediumValue" v-model="mediumData.medium_source_file_date_modified">
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Admin-Information:</span>
                <span v-if="!editMode" class="ctrlMediumValue">{{mediumData.medium_admin_info}}</span>
                <input v-if="editMode" class="ctrlMediumValue" v-model="mediumData.medium_admin_info">
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Sortierung:</span>
                <span v-if="!editMode" class="ctrlMediumValue">{{mediumData.medium_order_nr}}</span>
                <input v-if="editMode" class="ctrlMediumValue" v-model="mediumData.medium_order_nr">
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Datentyp:</span>
                <span v-if="!editMode" class="ctrlMediumValue">{{mediumData.medium_data_type}}</span>
                <select v-if="editMode" class="ctrlMediumValue" v-model="mediumData.medium_data_type">
                    <option value="text">Text</option>
                    <option value="image">Bild</option>
                    <option value="audio">Audio</option>
                    <option value="video">Video</option>
                </select>
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Sprache:</span>
                <span v-if="!editMode" class="ctrlMediumValue">{{mediumData.medium_lang}}</span>
                <select v-if="editMode" class="ctrlMediumValue" v-model="mediumData.medium_lang">
                    <option>deu</option>
                    <option>eng</option>
                    <option>spa</option>
                    <option>hun</option>
                </select>
            </div>
            <div class="ctrlMediumKeyValue">
                <span class="ctrlMediumKey">Url:</span>
                <span v-if="!editMode" class="ctrlMediumValue">{{mediumData.medium_url}}</span>
                <input v-if="editMode" class="ctrlMediumValue" v-model="mediumData.medium_url">
            </div>
        </div>
    </Transition>
    <div v-if="currentTab=='highlighted'" v-html="html"></div>
    <div v-if="currentTab=='editor'">
        <mavon-editor 
            v-model="markdown"
            language="de"
            :subfield="false"
            @change="textModified"
            @save="save"
        ></mavon-editor>
    </div>
  </div>    
</template>
<style>
    div.ctrlMedium {
        text-align: left;
        margin-left: 20px;
        margin-right: 20px;
    }
    div.ctrlMediumTitleFrame {
        display: flex;
    }
    div.ctrlMediumTitle {
        flex-grow: 1;
    }
    span.ctrlMediumKey {
        font-weight: 700;
    }
    span.ctrlMediumValue {
    }
    div.ctrlMediumKeyValue {
        font-size: 0.6em;
    }
    .ctrlMediumToolbarLink {
        margin-right: 30px;
        cursor: pointer;
        color: #333333;
    }
/*
    div.PjkDokAsHtmlOrigPageNo {
        font-size: 0.8em;
        font-weight: 700;
        text-align: center;
    }
    a.PjkDokAsHtmlFootnote {
        font-size: 0.7em;
        font-weight: 700;
        text-decoration: none;
        vertical-align:super;
    }
    table.PjkDokAsHtmlMetadata {
        color: #555555;
        font-size: 0.8em;
        border: 1px solid #555555
    }
*/
    .ctrlMediumLinkBtn {
        position: absolute;
        top:10px;
        right:25px;
    }
    .ctrlMediumLinkBtn a {
    }
    .ctrlMediumEditBtn{
        position: absolute;
        top:7px;
        right:50px;
        font-size: 1.3em;
        cursor: pointer;
    }
    div.ctrlMediumPageBreak {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4px 0;
        position: relative;
    }
    div.ctrlMediumPageBreak::before {
        content: "";
        flex: 1;
        border-bottom: 1px solid #999999;
    }
    div.ctrlMediumPageBreak span {
        padding: 0 10px;
        font-size: 0.7em;
        color: #999999;
    }
</style>
<script>
import SrvServerAccess from '../global/SrvServerAccess';
import SrvPjkDok2Html from '../global/SrvPjkDok2Html';
import SrvMessages from '../global/SrvMessages';
import DirPermission from './DirPermission';
import SrvSnippetMarker from '../global/SrvSnippetMarker';

export default {
    props: [
        'medium-id',
        'highlighted', // content of fulltext search field in order to highlight within text
        'show-link-button'
    ],
    data: function() {
        return  {
            mediumData: {},
            metadataExpanded: false,
            currentTab: 'highlighted',
            hasBeenModified: false,
            markdown: '',   // Markdown code for Editor.
            html: '', // HTML code for normal view.
            editMode: false,
            showPageNr: true
        };
    },
    created: function() {
        if (this.mediumId)
            this.loadMedium();
    },
    watch: {
        mediumId: function(val) {
            if (val)
                this.loadMedium();
        }
    },
    computed: {
        directLink: function() {
            return '/medium:'+this.mediumId;
        }
    },
    beforeDestroy: function() {
        if (this.hasBeenModified)
            if (confirm('Sollen Änderungen gespeichert werden?'))
                alert('TODO bitte Speichern-Button verwenden!');
    },
    methods: {
        loadMedium: function() {
            const that = this;
            const data = {
                 s_text:this.highlighted,
                 text_id: this.mediumId
            };
            SrvServerAccess.request('POST', '/api/mediumGet', data).then(
                function(serverData) {
                    // Format of serverData:
                    // {data: {medium_id:..., medium_source_file:..., event: {event_id:..., event_translation:[...],keywords:[],
                    //         version: {version_id:4, ...}, medium_text_to_html: ..., medium_text_to_html_highlighted: ...
                    that.mediumData = serverData.data[0];
                    that.markdown = that.mediumData.medium_text;
                    that.html = that.mediumData.medium_text_to_html_highlighted;
                    if (that.showPageNr)
                        that.html = that.html.replace(/\[(\d+)\]/g,'<div class="ctrlMediumPageBreak"><span>Seite $1</span></div>'); 
                    else
                        that.html = that.html.replace(/\[\d+\]/g,'');
                    if (that.highlighted && (that.highlighted.indexOf('ES$:')==0)) {
                        that.html = SrvSnippetMarker.insertMarker(that.highlighted.substr(4).split(','), that.html)
                        . split(SrvSnippetMarker.START).join('<span class="term-1">')
                        . split(SrvSnippetMarker.ENDE).join('</span>');
                    }
                }
            );
        },
        toggleMetadata: function() {
            this.metadataExpanded = ! this.metadataExpanded;
        },
        togglePageNr: function() {
            this.showPageNr = ! this.showPageNr;
            this.loadMedium();
        },
        setEditMode: function(editMode, save) {
            this.editMode = editMode;
            this.currentTab = editMode ? 'editor' : 'highlighted';
            if (! editMode) {
                if (save) this.save();
                else      this.markdown = this.mediumData.medium_text;
            }
        },
        setTab: function(tab) {
            this.currentTab = tab;
        },
        textModified: function() {
            this.hasBeenModified = true;
        },
        save: function() {
            const data = {
                _method: 'patch',
                medium_id: this.mediumId,
                medium_data_type: this.mediumData.medium_data_type,
                medium_source_file: this.mediumData.medium_source_file,
                medium_source_file_date_modified:this.mediumData.medium_source_file_date_modified,
                medium_event_id: this.mediumData.medium_event_id,
                medium_version_id: this.mediumData.medium_version_id,
                medium_order_nr:this.mediumData.medium_order_nr,
                medium_title: this.mediumData.medium_title,
                medium_url: this.mediumData.medium_url,
                medium_lang:this.mediumData.medium_lang,
                medium_admin_info: this.mediumData.medium_admin_info,
                medium_text: this.markdown
            };
            const that = this;
            SrvServerAccess.request('POST', '/api/medium/'+this.mediumId, data).then(
                function(serverResponse) {
                    SrvMessages.info(serverResponse.data.message);
                    // within readonly view is still the old text
                    // mediumData.medium_text_to_html_highlighted=markdown   will overwrite html by markdown
                    // better: relaod all
                    that.loadMedium();
                }
            );
            this.hasBeenModified = false;
        }
    },
    directives: {
        DirPermission: DirPermission
    }
}
</script>
