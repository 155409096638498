<template>
    <div>
        <b-modal id="modal" size="lg" 
                :title=modalTitle 
                :ok-variant=modalOkVariant 
                :ok-title=modalOkTitle 
                @ok="ok" 
                @cancel="cancel"
                @hide="cancel"
                 >
            <form>
                <b-container>
                    <b-form-row>
                        <b-col cols="8">
                            <label>Title</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_title" placeholder="Title">
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="8">
                            <label>Subtitle</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_subtitle" placeholder="Subtitle">
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="3">
                            <label>Event Abbreviation</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_abbrev" placeholder="Abbrev.">
                        </b-col>
                        
                        <b-col cols="2">
                            <label>Language</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.event_transl_lang">
                                <option v-for="(item,index) in languages" :key="index">{{item}}</option>
                            </select>
                        </b-col>
                        <b-col cols="2">
                            <label>Original language</label><br />
                            <input type="checkbox" :value="true"  v-model="formData.event_transl_lang_orig"/>
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="8">
                            <label>Notes</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_notes">
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="8">
                            <label>Attributes JSON</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_attributes">
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="8">
                            <label>Admin Keywords</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_admin_keywords">
                        </b-col>
                        <b-col cols="2">
                            <br />
                            <button @click="deleteRecord($event)" class="btn btn-danger" >Delete Transl.</button>
                        </b-col>  
                    </b-form-row>
                </b-container>
            </form>
        </b-modal>
    </div>
</template>

<script>
import {serverUrl} from '@/configuration';
import SrvMessages from '../../global/SrvMessages';
import axios from 'axios';
var $http = require('axios');

export default {
    props: {
        recordId: Number
    },
    data(){
        return {
            modalTitle:`Edit translation id: ${this.recordId}`,
            modalOkTitle:"Update Translation",
            modalOkVariant:"primary",
            formData:{
                event_transl_event_id: this.recordId ,
                event_transl_title: "",
                event_transl_subtitle: "",
                event_transl_abbrev: "",
                event_transl_notes: "",
                event_transl_lang: "",
                event_transl_lang_orig: false,
                event_transl_admin_keywords: "",
                event_transl_attributes: ""
            },
            languages: ['deu','eng','spa','hun'],
            
        }
    },
    mounted(){
        //this.modalTitle = this.modalTitle +': Id ' + this.recordId;
        this.showRecord()
        this.$bvModal.show('modal');
    },
    methods:{
        async showRecord(){
            await $http.get(serverUrl+`/api/translation/${this.recordId}`, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.formData = response.data
                //console.log(this.formData)
                this.formData._method = "patch" //for laravel update PUT/PATCH hier only post
            }).catch(error=>{
                SrvMessages.error(error);
            })
        },
        //update Event
        async ok(e){
            e.preventDefault();
            await $http.post(serverUrl+`/api/translation/`+ this.recordId,this.formData, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.$emit('messageFromChild', true) //re-write parent
            }).catch(error=>{
                SrvMessages.error(error);
            })
        },
        cancel(e){
            e.preventDefault();
            if(e.trigger!='ok'){this.$emit('messageFromChild', false);} //re-write false
        }
        ,
        deleteRecord(e){
            e.preventDefault()
            this.$confirm("Are you sure to delete this translation ?", "Delete Translation", "warning")
                .then(() => {
                    $http.delete(serverUrl +`/api/translation/${this.recordId}`, {
                        headers: {
                        Authorization: 'Bearer '+this.$store.state.sessionToken
                        }
                        }).then(response=>{
                            this.$emit('messageFromChild', true) //re-write parent
                        }).catch(error=>{
                            SrvMessages.error(error);
                        })
                });
        },
    }
}




</script>