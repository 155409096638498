<template>
    <div>
        <b-modal id="modal" size="lg" 
                :title=modalTitle 
                :ok-variant=modalOkVariant 
                :ok-title=modalOkTitle 
                @ok="ok" 
                @cancel="cancel"
                @hide="cancel"
                 >
            <form>
                <b-container>
                    <b-form-row>
                        <b-col cols="4">
                            <label>Version title</label>
                            <input type="text" class="form-control" v-model="formData.version_title" placeholder="versions title">
                        </b-col>
                        <b-col cols="1">
                            <label>Language</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.version_lang">
                                <option v-for="(item,index) in languages" :key="index">{{item}}</option>
                            </select>
                        </b-col>
                        <b-col cols="2">
                            <label>Default version</label><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="checkbox"  v-model="formData.version_default"/>
                        </b-col>
                        <b-col cols="3">
                            <label>Parent Version:</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.version_parent_id">
                                <option value=0>------</option>
                                <option  v-if="(version.version_id != recordId)" v-for="version in versions" :key="version.version_id" :value="version.version_id">{{version.version_title}} (version id: {{version.version_id}})</option>
                            </select>
                        </b-col>
                        <b-col cols="2">
                            
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="12">
                            <label>Version description</label>
                            <b-form-textarea
                                id="textarea"
                                v-model="formData.version_description"
                                placeholder="Short description of Editon"
                                rows="4"
                                max-rows="12"
                                ></b-form-textarea>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col cols="2">
                            <label>Data type:</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.version_data_type">
                                <option v-for="quality in data_type" :key="quality.code" :value="quality.code">{{quality.title}}</option>
                            </select>
                        </b-col>
                        <b-col cols="4">
                            <label>Transcription quality:</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.version_transcription_quality">
                                <option v-for="quality in trancription_qualities" :key="quality.code" :value="quality.code">{{quality.title}}</option>
                            </select>
                        </b-col>
                        <b-col cols="4">
                            <label>Translation quality:</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.version_translation_quality">
                                <option v-for="quality in translation_qualities" :key="quality.code" :value="quality.code">{{quality.title}}</option>
                            </select>
                        </b-col>
                        <b-col cols="4">
                            <label>Source information:</label>
                            <input type="text" class="form-control" v-model="formData.version_source_info">
                        </b-col>
                    </b-form-row>            
                    <b-form-row>
                        <b-col cols="12">
                            <label>Publication (if not connected to a publication through Publication Id. below)</label>
                            <input type="text" class="form-control" v-model="formData.version_publication" placeholder="Publication Titel/Where Published/Year etc.">
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="2">
                            <label>Publication Id.</label>
                            <input type="text" class="form-control" v-model="formData.version_publication_id">
                        </b-col>
                        <b-col cols="2">
                            <label>Page Nr. From</label>
                            <input type="text" class="form-control" v-model="formData.version_publication_page_from">
                        </b-col>
                        <b-col cols="2">
                            <label>Page Nr. To</label>
                            <input type="text" class="form-control" v-model="formData.version_publication_page_to">
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="12">
                            <label>Admin information:</label>
                            <input type="text" class="form-control" v-model="formData.version_admin_information">
                        </b-col>
                    </b-form-row>
                </b-container>
            </form>
        </b-modal>
    </div>
</template>

<script>
import {serverUrl} from '@/configuration';
import SrvMessages from '../../global/SrvMessages';
import axios from 'axios';
var $http = require('axios');


export default {
    props: {
        recordId: Number
    },
    data(){
        return {
            modalTitle:`Add new version to event ${this.recordId}`,
            modalOkTitle:"Save new version",
            modalOkVariant:"primary",
            formData:{
                version_event_id: this.recordId,
                version_title: "",
                version_description: "",
                version_lang: 'deu',
                version_default: false,
                version_parent_id: null,
                version_source_info: "",
                version_transcription_quality: "",
                version_translation_quality: "",
                version_admin_information: "",
                version_publication_id: "",
                version_publication: "",
                version_publication_page_from: "",
                version_publication_page_to: "",
                version_data_type:"text",
                version_tmp_metadata:"",
            },
            languages: ['deu','eng','spa','hun'],
            trancription_qualities: [
                    {code: '', title: '(nicht zugeordnet)'},
                    {code: 'A', title: 'A - Eigenhändig, Diktat'},
                    {code: 'B', title: 'B - Text vom Tonband, nicht oder wenig bearbeitet'},
                    {code: 'C', title: 'C - Text vom Tonband, bearbeitet, meist gekürzt'},
                    {code: 'D', title: 'D - Gute Mitschrift'},
                    {code: 'E', title: 'E - Mittlere Mitschrift'},
                    {code: 'F', title: 'F - Schlechte Mitschrift'},
                    {code: 'G', title: 'G - Notizen, Berichte'},
                    {code: 'H', title: 'H - Dispositionen'},
                        ],
            translation_qualities: [
                    {code: '', title: '(nicht zugeordnet)'},
                    {code: 'A', title: 'A - ofiziell aprobiert'},
                    {code: 'B', title: 'B - gute Übersetzung'},
                    {code: 'C', title: 'C - Mässig'},
                    {code: 'D', title: 'D - schlecht'},
                        ],
            data_type: [
                    {code: 'text', title: 'Text'},
                    {code: 'image', title: 'Bild'},
                    {code: 'audio', title: 'Audio'},
                    {code: 'video', title: 'Video'},
                        ],
            versions: [],
            
        }
    },

    mounted(){
        this.getVersions();
        this.$bvModal.show('modal');
    },
    methods:{
        async getVersions(){
            await $http.post(serverUrl+`/api/GetVersion`,{event_id: this.recordId} ,{
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.versions = response.data
                //this.$emit('messageFromChild', true) //re-write parent
                //this.modalTitle = this.modalTitle + ' for Event Id: '+ this.formData.version_event_id;
                //console.log(response.data)
                //this.formData._method = "patch" //for laravel update PUT/PATCH hier only post
            }).catch(error=>{
                SrvMessages.error(error);
            })
        },
        
        
        
        async ok(e){
            e.preventDefault();
            //console.log(this.formData);
            await $http.post(serverUrl+'/api/version',this.formData, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.$emit('messageFromChild', true); //revrite true
                
            }).catch(error=>{
                SrvMessages.error(error);
            })
            
        },

        cancel(e){
            e.preventDefault();
            if(e.trigger!='ok'){this.$emit('messageFromChild', false);} //re-write false
        }
        
    }
}
</script>