<!--
One Medium as single line component
-->
<template>
  <div class="ctrlMediumSmall" v-on:click="click" :style="{'font-size':config?.small?'0.8em':'1em'}">
    &#x1F4C4;
    <span v-if="mediumId" class="ctrlMediumSmallFile" title="Medium Dateiname">
        {{theRecord?.medium_source_file}}
    </span>
    <span v-if="mediumId" class="ctrlMediumSmallTitle" title="Medium Titel">
        {{theRecord?.medium_title}}
    </span>
    <span v-if="mediumId" class="ctrlMediumSmallId" @click="id2clipboard" title="Medium ID in Zwischenablage kopieren">
      M-ID:{{mediumId}}
    </span>
  </div>
</template>
<style>
    div.ctrlMediumSmall {
        cursor: pointer;
    }
    span.ctrlMediumSmallId {
        margin-left: 5px;
        font-weight: 700;
        font-size: 0.8em;
        color: #777777;
        cursor: pointer;
    }
    span.ctrlMediumSmallTitle {
        margin-left: 5px;
        font-weight: 700;
        font-size: 0.8em;
        color: #7777aa;
    }
    span.ctrlMediumSmallFile {
        margin-left: 5px;
    }
</style>
<script>

import SrvUiHelper from '../global/SrvUiHelper';
import SrvServerAccess from '../global/SrvServerAccess';

export default {
    props: [
        'config', // {    small:true,   true use a more little font
                  //      onclick:function } to be invoced by a click
        'id',     // ID of medium (record data are reloaded from server)
        'record', // {version-id:...}   full record as alternative to id.
    ],
    // $event(click, versionId)
    data: function() {
        return  {
            theRecord: this.record
        };
    },
    computed: {
        mediumId: function() {
            if (this.id)
                return this.id;
            return this.theRecord ? this.theRecord.medium_id : '';
        }
    },
    watch: {
        record: function(newVal) {
            this.theRecord = newVal;
        }
    },
    mounted: function() {
        if (this.id  && !this.record)
            this.loadData();
    },
    methods: {
        click: function() {
            if (this.config && this.config.onclick)
                this.config.onclick();
            this.$emit('click');
        },
        loadData: function() {
            if (!this.id)
                return;
            const that = this;
            SrvServerAccess.request('POST','/api/mediumGet', {text_id: this.id}).then(
                function(serverData) {
                    that.theRecord = (serverData && serverData.data) ? serverData.data[0] : {};
                },
                function(error) {
console.error(error);
                }
            );
        },
        id2clipboard: function(event) {
            SrvUiHelper.copyValueToClipboard(event, this.mediumId);
        }
    }
}
</script>
