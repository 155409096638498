<!--
A checkbox styled as slider
-->
<template>
 <label class="sliderCheckboxSwitch">
  <input type="checkbox" v-model="localValue">
  <span class="sliderCheckboxSlider"></span>
 </label>
</template>
<style>
/* box around slider */
.sliderCheckboxSwitch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}
/* hide default HTML checkbox */
.sliderCheckboxSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* the slider */
.sliderCheckboxSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 17px;
}
.sliderCheckboxSlider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 50%;
}
input:checked + .sliderCheckboxSlider {
  background-color: #2196F3;
}
input:focus + .sliderCheckboxSlider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .sliderCheckboxSlider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
</style>
<script>
export default {
    props: [
        'value'
    ],
    data: function() {
        return {
            localValue: this.value
        };
    },
    watch: {
        localValue(newValue) {
            this.$emit('input', newValue);
        },
        value(newValue) {
            this.localValue = newValue;
        }
    }
}
</script>
