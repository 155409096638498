<!--
Wrapper for CmpCtrlEvent to be used as RouterView, which gets eventId from routerData.
-->
<template>
  <div class="event">
    <cmp-ctrl-event
      :event-id="eventId"
      @open-version="openVersion"
      @open-medium="openMedium"
      @open-comments="openComments"
    ></cmp-ctrl-event>
    <cmp-ctrl-overlay-for-nested-data 
        :version-id="nestedVersionId"
        :event-id="nestedEventId"
        :medium-id="nestedMediumId"
        :type="overlayType"
        @open-version="openVersion"
        @open-medium="openMedium"
        @close="closeOverlay"
    ></cmp-ctrl-overlay-for-nested-data>
  </div>    
</template>
<style>
</style>
<script>
import CmpCtrlEvent from '@/controls/CmpCtrlEvent.vue';
import CmpCtrlOverlayForNestedData from '@/controls/CmpCtrlOverlayForNestedData.vue';
export default {
    data: function() {
        return  {
            overlayType: '',
            eventId: '',
            nestedVersionId: '',
            nestedEventId: '',
            nestedMediumId: ''
        };
    },
    created: function() {
        this.eventId = this.$route.params.id && this.$route.params.id.length ? this.$route.params.id.substr(1) : '';
    },
    components: {
        CmpCtrlEvent: CmpCtrlEvent,
        CmpCtrlOverlayForNestedData: CmpCtrlOverlayForNestedData
    },
    methods: {
        openVersion: function(eventAndVersionId) {
            this.overlayType     = 'version';
            this.nestedVersionId = eventAndVersionId.versionId;
            this.nestedEventId   = eventAndVersionId.eventId;
            this.nestedMediumId  = '';
        },
        openMedium: function(mediumId) {
            this.overlayType     = 'medium';
            this.nestedVersionId = '';
            this.nestedEventId   = '';
            this.nestedMediumId  = mediumId;
        },
        openComments: function() {
            this.overlayType     = 'comments';
            this.nestedVersionId = '';
            this.nestedEventId   = this.eventId;
            this.nestedMediumId  = '';
        },
        closeOverlay: function(mediumId) {
            this.nestedVersionId = '';
            this.nestedEventId   = '';
            this.nestedMediumId  = '';
            this.overlayType     = '';
        }
    }
}
</script>
