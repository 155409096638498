<!--
a Tree control
-->
<template>
    <div class="ctrlTree">
        <div v-for="item,idx in config">
            <span v-if="!item.type" :currentIdx="idx" :style="getStyle(item)" :title="item.tooltip" v-on:click="onclick" v-html="icon2html(item.icon)"></span>
            <span v-if="!item.type" :style="getStyle(item)" :title="item.tooltip" v-on:click="onclick">{{item.text}}</span>
            <cmp-ctrl-medium-small  v-if="item?.config?.type=='medium'"  :record="item?.record" :id="item?.id" :config="item?.config"/>
            <cmp-ctrl-version-small v-if="item?.config?.type=='version'" :record="item?.record" :id="item?.id" :config="item?.config"/>
            <cmp-ctrl-event-small   v-if="item?.type=='event'"   :record="item?.record" :id="item?.id" :onclick="item?.onclick"/>
            <ctrl-tree config v-if="item.children" :config="item.children"></ctrl-tree>
        </div>
    </div>
</template>
<style>
    div.ctrlTree {
        padding-left: 15px;
    }
</style>
<script>
import CmpCtrlEventSmall from '@/controls/CmpCtrlEventSmall.vue';
import CmpCtrlVersionSmall from '@/controls/CmpCtrlVersionSmall.vue';
import CmpCtrlMediumSmall from '@/controls/CmpCtrlMediumSmall.vue';

export default {
    name: 'ctrl-tree',
    props: [
        'config'  // [
                  //   {text:'anzeige 1', icon:'&#x2315;', onclick:function, tooltip:... },
                  //   {text:'anzeige 2', icon:'&#x2710;', onclick:function, children:[
                  //       {text:'anzeige 21', icon:'&#x2795;', onclick:function },
                  //       {text:'anzeige 22', icon:'&#x2611;' }
                  //   ]},
                  //   {type:'event',   onclick:function, record:{...}, id:...},    --> Display CmpCtrlEventSmall   with record/id/onclick
                  //   {type:'version', onclick:function, record:{...}, id:...},    --> Display CmpCtrlVersionSmall with record/id/onclick
                  //   {type:'medium',  onclick:function, record:{...}, id:...}     --> Display CmpCtrlMediumSmall  with record/id/onclick
                  // ]
    ],
    data: function() { 
        return {
        };
    },
    methods: {
        icon2html: function(icon) { 
            return icon;
        },
        getStyle: function(item) {
            return item.onclick ? {cursor: 'pointer'} : {};
        },
        onclick: function(event) {
            const idx = event.target.getAttribute('currentIdx');
            const item = this.config[idx];
            if (item.onclick)
                item.onclick();
        }
    },
    components: {
        CmpCtrlMediumSmall:  CmpCtrlMediumSmall,
        CmpCtrlVersionSmall: CmpCtrlVersionSmall,
        CmpCtrlEventSmall:   CmpCtrlEventSmall
    }
}
</script>

