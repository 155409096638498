<template>
    <div>
        <b-modal id="modal" size="lg" 
                :title=modalTitle 
                :ok-variant=modalOkVariant 
                :ok-title=modalOkTitle 
                @ok="ok" 
                @cancel="cancel"
                @hide="cancel">
            <form>
                <b-container>
                    <b-form-row>
                        <b-col cols="1">
                            <label>Event ID</label>
                            <input type="text" class="form-control" v-model="formData.medium_event_id" :disabled="1">
                        </b-col>
                        <b-col cols="1">
                            <label>Version ID</label>
                            <input type="text" class="form-control" v-model="formData.medium_version_id" :disabled="1">
                        </b-col>
                        <b-col cols="8">
                            <label>Source File</label>
                            <input type="text" class="form-control" v-model="formData.medium_source_file" placeholder="">
                        </b-col>
                        <b-col cols="2">
                            <label>Source File date</label>
                            <input type="text" class="form-control" v-model="formData.medium_source_file_date_modified" placeholder="">
                        </b-col>
                    </b-form-row> 
                    <br />   
                    <b-form-row>
                        <b-col cols="12">
                            <label>Admin information</label>
                            <input type="text" class="form-control" v-model="formData.medium_admin_info">
                        </b-col>
                    </b-form-row> 
                    <br />
                    <b-form-row>
                        <b-col cols="1">
                            <label>Sorting Nr.:</label>
                            <input type="text" class="form-control" v-model="formData.medium_order_nr">
                        </b-col>
                    
                        <b-col cols="9">
                            <label>Title (e.g. "Chapter 1 : Our Father")</label>
                            <input type="text" class="form-control" v-model="formData.medium_title" placeholder="Publication Titel/Where Published/Year etc.">
                        </b-col>
                        <b-col cols="1">
                            <label>Data type:</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.medium_data_type">
                                <option v-for="quality in data_type" :key="quality.code" :value="quality.code">{{quality.title}}</option>
                            </select>
                        </b-col>
                        <b-col cols="1">
                            <label>Language</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.medium_lang">
                                <option v-for="(item,index) in languages" :key="index" >{{item}}</option>
                            </select>
                        </b-col>
                    </b-form-row>
                    <br />  
                     
                    <b-form-row>
                        <b-col cols="9">
                            
                        </b-col>
                        <b-col cols="1">
                            <br />
                            <button @click="cancel($event)" class="btn btn-secondary" >cancel</button>
                        </b-col>
                        <b-col cols="2">
                            <br />
                            <button @click="ok($event)" class="btn btn-primary" >Save new media</button>
                        </b-col>
                    </b-form-row>
                    <br />  
                    <b-form-row>

                        <b-col cols="12">
                            <label>Medium URL (or paste Medium Text in second text array below. BUT NOT BOUTH!</label>
                            <input type="text" class="form-control" v-model="formData.medium_url">
                        </b-col>
                        <b-col cols="12">
                            <label>Medium Text (MarkDown Format)</label>
                            <mavon-editor :toolbars="markdownOption" :language="markdownLanguage" v-model="formData.medium_text">
                        
                            </mavon-editor>
                            <!--
                                    <b-form-textarea
                                id="textarea"
                                v-model="formData.medium_text"
                                placeholder="text in HTML Format"
                                rows="100"
                                max-rows="1000"
                                ></b-form-textarea>
                            -->
                            
                        </b-col>    
                    </b-form-row>    
                    
                </b-container>
            </form>
        </b-modal>
    </div>
</template>

<script>
import {serverUrl} from '@/configuration';
import SrvMessages from '../../global/SrvMessages';
import axios from 'axios';


var $http = require('axios');


export default {
    props: {
        versionId: Number, //VersionId
        eventId: Number    //EventId
    },
    data(){
        return {
            modalTitle:`Add new MEDIUM for event id: ${this.eventId} and version id: ${this.versionId}`,
            modalOkTitle:"Save new medium",
            modalOkVariant:"primary",
            formData:{
                medium_id: 0,
                medium_data_type: "text",
                medium_source_file: "example file",
                medium_source_file_date_modified:"0000-12-12",
                medium_event_id: this.eventId,
                medium_version_id: this.versionId,
                medium_order_nr:"99",
                medium_title: "Title example",
                medium_text: "<H1>Test title</H1>  <b>Test text</b> ",
                medium_url: "http://test.url.de",
                medium_lang: "deu",
                medium_admin_info: "test admin info"
            },
            markdownOption: {
                    bold: true,
                    italic: true,
                    header: true,
                    underline: true,
                    strikethrough: true,
                    mark: true,
                    superscript: true,
                    subscript: true,
                    quote: true,
                    ol: true,
                    ul: true,
                    link: true,
                    imagelink: true,
                    code: true,
                    table: true,
                    fullscreen: true,
                    readmodel: true,
                    htmlcode: true,
                    help: true,
                    /* 1.3.5 */
                    undo: true,
                    redo: true,
                    trash: true,
                    save: true,
                    /* 1.4.2 */
                    navigation: true,
                    /* 2.1.8 */
                    alignleft: true,
                    aligncenter: true,
                    alignright: true,
                    /* 2.2.1 */
                    subfield: true,
                    preview: true
                },
            
            markdownLanguage: "en",
            
            languages: ['deu','eng','spa','hun'],
            
            data_type: [
                    {code: 'text', title: 'Text'},
                    {code: 'image', title: 'Bild'},
                    {code: 'audio', title: 'Audio'},
                    {code: 'video', title: 'Video'},
                        ],
        }
    },

    
    mounted(){
        //this.getVersions();
        this.$bvModal.show('modal');
    },
    methods:{
        async ok(e){
            e.preventDefault();
            //console.log(this.formData);
            await $http.post(serverUrl+`/api/medium`,this.formData, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.$emit('messageFromChild', true); //revrite true
                
            }).catch(error=>{
                SrvMessages.error(error);
            })
            
        },

        cancel(e){
            e.preventDefault();
            //if(e.trigger!='ok' && e.trigger!='esc'){this.$emit('messageFromChild', false);} //re-write false
            if(e.trigger!='ok'){this.$emit('messageFromChild', false);} //re-write false
        }
        
    }
}
</script>

