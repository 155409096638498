<!--
All data of a version of an event as a huge control element in order to be displayed on any view
-->
<template>
  <div class="ctrlVersion">
    <div class="ctrlVersionEditBtn" v-dir-permission="'g_EDITORS'" v-on:click="$emit('edit-version',{eventId:eventId,versionId:versionId})" title="Edit this Version">&#x270e;</div>
    <div v-if="showLinkButton" class="ctrlVersionLinkBtn" title="Direct link to this version"><a target="link" :href="directLink">&#x1F517;</a></div>
    <div class="ctrlVersionTitleFrame">
        <div class="ctrlVersionTitle">
            <h1>{{versionData.version_title || 'kein Titel'}}</h1>
        </div>
    </div>
    <div class="ctrlVersionIds">
        <span>ID: {{versionData.version_id}}</span>
        <span>Typ: {{versionData.version_data_type}}</span>
        <span>Sprache: <ctrl-language-icon :lang="versionData.version_lang"></ctrl-language-icon></span>
        <span>Transcription Qualität: {{versionData.version_transcription_quality}}</span>
    </div>
    <div>
        <span v-if="versionData.version_default" class="ctrlVersionKey">is default version:</span>
    </div>
    <div>
        <span class="ctrlVersionKey">Medien</span>
        <span v-dir-permission="'g_EDITORS'" class="ctrlVersionAddMedium" title="add medium" v-on:click="$emit('add-medium',{eventId:eventId,versionId:versionId})">&#x2795;</span>
        <div class="ctrlVersionMedium" 
            v-for="medium in media"
        >
          <span @click="$emit('open-medium', medium.id)">&#x1F4C4; Medium {{medium.id}}: {{medium.title}}</span>
          <span class="ctrlVersionEditMedium" v-dir-permission="'g_EDITORS'" v-on:click="$emit('edit-medium',medium.id)" title="Edit metadata for this medium">&#x270e;</span>
        </div>
    </div>
<!--
    <div>
        <span class="ctrlVersionKey">Admin-Information:</span> <span class="ctrlVersionValue">{{versionData.version_admin_information}}</span>
    </div>
-->
    <div>
        <span class="ctrlVersionKey">Beschreibung:</span> <span class="ctrlVersionValue">{{versionData.version_description}}</span>
    </div>
    <div>
        <span class="ctrlVersionKey">Publikation:</span> <span class="ctrlVersionValue">{{versionData.version_publication}}</span>
    </div>
    <div v-if="versionData.version_publication_page_from || versionData.version_publication_page_to">
        <span class="ctrlVersionKey">Publikation</span> <span class="ctrlVersionValue">Seite {{versionData.version_publication_page_from}} bis {{versionData.version_publication_page_to}}</span>
    </div>
    <div>
        <span class="ctrlVersionKey">Source info:</span> <span class="ctrlVersionValue">{{versionData.version_source_info}}</span>
    </div>
    <div>
        <span class="ctrlVersionKey">Subtree with child versions:</span>
    </div>
    <ctrl-tree :config="detailTree"></ctrl-tree>
  </div>    
</template>
<style>
    div.ctrlVersion {
        text-align: left;
        margin-left: 20px;
        margin-right: 20px;
    }
    div.ctrlVersionTitleFrame {
        display: flex;
    }
    div.ctrlVersionTitle {
        flex-grow: 1;
    }
    div.ctrlVersionBack {
        flex-grow: 0;
    }
    span.ctrlVersionKey {
        font-weight: 700;
    }
    span.ctrlVersionValue {
    }
    div.ctrlVersionMedium {
        margin-left: 10px;
    }
    div.ctrlVersionMedium span {
        cursor: pointer;
    }
    div.ctrlVersionIds span {
        font-size: 0.6em;
        margin-right: 30px;
    }
    h1 {
        font-size: 1.3em;
    }
    .ctrlVersionLinkBtn {
        position: absolute;
        top:10px;
        right:25px;
    }
    .ctrlVersionLinkBtn a {
    }
    .ctrlVersionEditBtn {
        position: absolute;
        top:5px;
        right:50px;
        font-size: 1.4em;
        cursor: pointer;
    }
    .ctrlVersionAddMedium {
        cursor: pointer;
    }
</style>
<script>
import SrvServerAccess from '../global/SrvServerAccess';
import CtrlLanguageIcon from '@/controls/CtrlLanguageIcon.vue'
import CtrlTree from '@/controls/CtrlTree.vue'
import DirPermission from './DirPermission';

export default {
    props: [
        'event-id',
        'version-id',
        'show-link-button'
        // emit open-medium(mediumId)
        // emit edit-version({eventId:...,versionId:...})
        // emit add-medium({eventId:...,versionId:...})
    ],
    data: function() {
        return  {
            versionData: {}
        };
    },
    computed: { 
        directLink: function() {
            return '/version:'+this.eventId+'-'+this.versionId;
        },
        media: function() {
            const media = [];
            if (this.versionData.media)
                this.versionData.media.forEach(function(mediumRecord) {
                    media.push({
                        id: mediumRecord.medium_id,
                        title: mediumRecord.medium_title ? mediumRecord.medium_title : mediumRecord.medium_source_file
                    });
                });
            return media;
        },
        detailTree: function() {
            const tree = [];
            this.versionData2tree(tree, 1, this.versionData);
            return tree;
        }
    },
    created: function() {
        if (this.eventId && this.versionId)
            this.loadVersion();
    },
    watch: {
        versionId: function(val) {
            if (val)
                this.loadVersion();
        },
        eventId: function(val) {
            if (val)
                this.loadVersion();
        }
    },
    methods: {
        loadVersion: function() {
            const that = this;
            SrvServerAccess.request('POST', '/api/GetVersionRecursive', {event_id:this.eventId}).then(
                function(serverData) {
                    // Format of serverData:
                    // {data:[{
                    //      version_id:...,
                    //      version_parent_id:...,
                    //      version_publication:'in: KASTNER...', 
                    //      child_versions: [...]}]}
                    that.versionData = that.searchVersion(serverData.data) || {};
                },
                function(error) {
console.error(error);
                }
            );
        },
        versionData2tree: function(treeParent, versionIdx, versionData) {
            const genericVersionTitle = 'Version '
                + versionIdx
                + (versionData.version_publication ? ' '+versionData.version_publication : '');
            let versionTitle = versionData.version_title || genericVersionTitle;
            if (versionData.version_lang) versionTitle += ' ('+versionData.version_lang+')';
            if (versionData.version_default) versionTitle += ' (DEFAULT)';
            const tooltip = (versionData.version_description ? versionData.version_description : '')
                          + versionData.version_default;
            const that = this;
//            const version = {text: versionTitle, icon:'&#x1F4C2;', tooltip: tooltip, onclick: function() {
//                that.versionData = versionData;
//            }};
            const version = {
                record: versionData,
                config: {
                    type:'version',
                    onclick: function() {
                        that.versionData = versionData;
                    }
                }
            };
            treeParent.push(version);
            if (versionData.child_versions && versionData.child_versions.length) {
                version.children = [];
                versionData.child_versions.forEach(function(childVersionData, childVersionIdx) {
                    that.versionData2tree(version.children, versionIdx+'-'+(childVersionIdx+1), childVersionData);
                });
            }
        },
        gotoSearch: function() {
            this.$router.push('/search:restore');
        },
        searchVersion: function(versions) {
            for (let i=0; i<versions.length; i++) {
                const version = versions[i];
                if (version.version_id == this.versionId)
                    return version;
                if (version.child_versions && version.child_versions.length) {
                    const ret = this.searchVersion(version.child_versions);
                    if (ret)
                        return ret;
                }
            }
            return null;
        }
    },
    components: {
        CtrlLanguageIcon: CtrlLanguageIcon,
        CtrlTree: CtrlTree
    },
    directives: {
        DirPermission: DirPermission
    }
}
</script>
