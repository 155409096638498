<!--
All data of an event as a huge control element in order to be displayed on any view
-->
<template>
  <div class="ctrlEvent">
    <div class="ctrlEventTitleFrame">
<!--
        <div class="ctrlEventTitle">
            <h1>TODO</h1>
        </div>
-->
    </div>
    <div class="searchEventIds">
        <span v-if="showLinkButton" class="ctrlEventLinkBtn" title="Direct link to this event"><a target="link" :href="directLink">&#x1F517;</a></span>
        <span class="ctrlEventEditBtn" v-dir-permission="'g_EDITORS'" v-on:click="$emit('edit-event',cachedEventData.event_id)" title="Edit this Event">&#x270e;</span>
        <span class="ctrlEventId">ID: {{cachedEventData.event_id}}</span>
        <span class="ctrlEventUuid">UUID: {{cachedEventData.event_uid}}</span>
        <span class="ctrlEventFilename">Filename: {{cachedEventData.event_file}}</span>
        <span class="ctrlEventTextfile">Textfile: {{cachedEventData.event_file_text}}</span>
    </div>
    <div>
      {{date}} 
      <span class="ctrlEventAccuracy">
        {{days}} {{accuracyFormatted}}
        {{qualityFormatted}}
      </span>
    </div>
    <div v-if="cachedEventData.event_source">
        <span v-if="cachedEventData.event_source">Quelle: {{cachedEventData.event_source}}</span><br/>
    </div>
    <cmp-ctrl-keywords :keywords="cachedEventData.keywords"></cmp-ctrl-keywords>
<!--
    <div v-if="cachedEventData.event_admin_keywords">
        <span class="ctrlEventKey">Admin-Keywords:</span> <span class="ctrlEventValue">{{cachedEventData.event_admin_keywords}}</span>
    </div>
-->
    <cmp-ctrl-translation
        :translation-data="translationData" 
        v-slot="slotProps"
        v-on:edit="$emit('edit-translation',{eventId:eventId,translationId:$event.id})"
        v-on:add="$emit('add-translation',{eventId:eventId,lang:$event})"
    >
        <div v-if="translationData[slotProps.lang]?.title">
            <span class="ctrlEventKey">Titel: </span>
            <span class="ctrlEventValue">{{translationData[slotProps.lang]?.title}}</span>
        </div>
        <div v-if="translationData[slotProps.lang]?.subtitle">
            <span class="ctrlEventKey">Untertitel: </span>
            <span class="ctrlEventValue">{{translationData[slotProps.lang]?.subtitle}}</span>
        </div>
        <div v-if="translationData[slotProps.lang]?.abbrev">
            <span class="ctrlEventKey">Abkürzung: </span>
            <span class="ctrlEventValue">{{translationData[slotProps.lang]?.abbrev}}</span>
        </div>
        <div v-if="translationData[slotProps.lang]?.notes">
            <span class="ctrlEventKey">Bemerkung: </span>
            <span class="ctrlEventValue">{{translationData[slotProps.lang]?.notes}}</span>
        </div>
    </cmp-ctrl-translation>
    <div class="ctrlEventAddVersion" v-dir-permission="'g_EDITORS'" title="add version" v-on:click="$emit('add-version',eventId)">&#x2795;</div>
    <ctrl-tree :config="detailTree"></ctrl-tree>
    <div class="ctrlEventKey ctrlEventClickable" v-if="cachedEventData.event_comment_count" @click="$emit('open-comments',eventId)">
        {{cachedEventData.event_comment_count}} Kommentar{{(cachedEventData.event_comment_count>1)?'e':''}}
    </div>
  </div>    
</template>
<style>
    div.ctrlEvent {
        text-align: left;
        margin-left: 5px;
        margin-right: 5px;
        border: 1px solid #777777;
    }
    div.ctrlEventTitleFrame {
        display: flex;
    }
    div.ctrlEventTitle {
        flex-grow: 1;
    }
    div.ctrlEventBack {
        flex-grow: 0;
    }
    .ctrlEventKey {
        font-weight: 700;
    }
    span.ctrlEventValue {
    }
    h1 {
        font-size: 1.3em;
    }
    div.searchEventIds span {
    }
    span.ctrlEventAccuracy {
        margin-left: 10px;
        font-size: 0.7em;
    }
    .ctrlEventClickable {
        cursor: pointer;
    }
    .ctrlEventLinkBtn {
        margin-right: 0px;
        font-size: 0.6em;
    }
    .ctrlEventLinkBtn a {
    }
    .ctrlEventEditBtn {
        font-size: 0.9em;
        margin-right: 30px;
        cursor: pointer;
    }
    .ctrlEventId {
        margin-right: 30px;
        font-size: 0.6em;
    }
    .ctrlEventUuid {
        margin-right: 30px;
        font-size: 0.6em;
    }
    .ctrlEventFilename {
        margin-right: 30px;
        font-size: 0.6em;
    }
    .ctrlEventTextfile {
        font-size: 0.6em;
    }
    .ctrlEventAddVersion {
        font-size:0.8em;
        cursor:pointer;
    }
</style>
<script>
import SrvServerAccess from '../global/SrvServerAccess';
import SrvFormatHelper from '../global/SrvFormatHelper';
import CmpCtrlTranslation from '@/controls/CmpCtrlTranslation.vue'
import CtrlTree from '@/controls/CtrlTree.vue'
import CmpCtrlKeywords from '@/controls/CmpCtrlKeywords.vue'
import DirPermission from './DirPermission';
//import SrvMessages from '../global/SrvMessages';

export default {
    props: [
        'event-id',              // ID in order to load data from server
  ///      'event-data',            // if the event record is given, it will not be reloaded from server
/*
record.medium_text_short
eventFromServer.event_start_date
eventFromServer.event_end_date
eventFromServer.event_accuracy
eventTextRecord.title 
eventTextRecord.subTitle
event.event_translation [{
 event_transl_lang
 event_transl_subtitle
 event_transl_title
*/
        'show-link-button'
    ],
    // emit  open-version({eventId:..., versionId:...})
    // emit  open-medium(mediumId)
    // emit  open-comments(eventId)
    // emit  edit-event(eventId)
    // emit  add-version({eventId:..., versionId:...})
    // emit  edit-translation({eventId:..., trabslationId})
    // emit  add-translation({eventId:..., lang})
    data: function() {
        return  {
            loadState: 'empty', // one of empty, event, versions
            cachedEventData: getEmptyData(),
            detailTree: null // ConfigData for CtrlTree in order to show versions and mediums
        };
    },
    computed: {
        directLink: function() {
            return '/event:'+this.eventId;
        },
        date: function() {
            let date = SrvFormatHelper.dateSql2deutsch(this.cachedEventData.event_start_date) || '';
            if (this.cachedEventData.event_end_date && (this.cachedEventData.event_start_date != this.cachedEventData.event_end_date))
                date += ' - ' + SrvFormatHelper.dateSql2deutsch(this.cachedEventData.event_end_date);
            return date;
        },
        days: function() {
            if (! this.cachedEventData.event_duration)  return '';
            if (this.cachedEventData.event_duration==1) return '1 Tag';
            return                this.cachedEventData.event_duration + ' Tage';
        },
        accuracyFormatted: function() {
            return this.cachedEventData.event_accuracy
                ?  '(Genauigkeit: '+this.cachedEventData.event_accuracy+')'
                : '';
        },
        qualityFormatted: function() {
            return this.cachedEventData.event_text_quality
                ?  '(Textqualität: '+this.cachedEventData.event_text_quality+')'
                : '';
        },
        keywords: function() {
            // cachedEventData.keywords:
            // [{key_keyword:..., key_category:..., key_lang:deu}]
            if (!this.cachedEventData.keywords || !this.cachedEventData.keywords.length) return '';
            const keywords = [];
            this.cachedEventData.keywords.forEach(function(record) {
                keywords.push(record.key_keyword);
            });
            return keywords.join(',');
        },
        translationData: function() {
            const ret = {};
            if (this.cachedEventData.event_translation)
                this.cachedEventData.event_translation.forEach(function(record){
                    const lang = record.event_transl_lang;
                    ret[lang] = {
                        id:       record.event_transl_id,
                        title:    record.event_transl_title,
                        subtitle: record.event_transl_subtitle,
                        abbrev:   record.event_transl_abbrev,
                        notes:    record.event_transl_notes,
                    };
                });
            return ret;
        }
    },
    created: function() {
//        if (this.eventId && this.eventData) {
//            this.cachedEventData = this.eventData;
//            this.loadState = 'event';
//        }
        this.ensureDataLoaded();
    },
    watch: {
        eventId: function(newEventId) {
            // force reload
            this.loadState = 'empty';
            this.cachedEventData = getEmptyData();
            this.detailTree = null;
            this.ensureDataLoaded();
        }
    },
    methods: {
        ensureDataLoaded: function() {
            if (!this.eventId)
                return;
            const that = this;
            if (this.loadState=='empty') {
                // load event
                SrvServerAccess.request('POST','/api/eventGet', {event_id: this.eventId}).then(
                    function(serverData) {
                        if (serverData && serverData.data && serverData.data.event) {
                            that.cachedEventData = serverData.data.event[0];
                            that.loadState = 'event';
                            that.ensureVersionsLoaded();
                        }
                    },
                    function(error) {
console.error(error);
                    }
                );
            } else 
                this.ensureVersionsLoaded();
        },
        ensureVersionsLoaded: function() {
            if (this.loadState=='versions') // already loaded
                return;
            const that = this;
            this.detailTree = [];
            SrvServerAccess.request('POST', '/api/GetVersionRecursive', {event_id: this.eventId}).then(
                function(serverData) {
                    // Format of serverData:
                    // {data:[{
                    //      version_id:...,
                    //      version_parent_id:...,
                    //      version_publication:'in: KASTNER...', 
                    //      child_versions: [...]}]}
                    let versionIdx = 1;
                    serverData.data.forEach(function(versionData) {
                        // Icons:      
                        // Metatada  &#x1F4AC;    &#x1F5E9;   &#x1F582;
                        // Version   &#x1F4C2;    &#x1F5C1;   &#x2710;
                        // Medium    &#x1F4C4;    &#x1F5D2;   &#x1F4D3;
                        versionData2tree(that, that.detailTree, versionIdx++, versionData);
                        let mediumIdx = 1;
                        versionData.media.forEach(function(mediumData) {
                            that.detailTree.push({
                                config: {
                                    small:false,
                                    type:'medium',
                                    onclick: function() {
                                        that.$emit('open-medium', mediumData.medium_id);
                                    }
                                },
                                record: mediumData,
                            });
                        });
                    });
                }, function(error) {
console.error(error);
                }
            );
        }
    },
    components: {
        CtrlTree: CtrlTree,
        CmpCtrlKeywords: CmpCtrlKeywords,
        CmpCtrlTranslation: CmpCtrlTranslation
    },
    directives: {
        DirPermission: DirPermission
    }
}

function versionData2tree(controlInstance, treeParent, versionIdx, versionData) {
/*
version_data_type
version_publication
version_publication_id
version_publication_page_from
version_publication_page_to
version_source_info
version_tmp_metadata
version_transcription_quality
version_translation_quality
*/
    const genericVersionTitle = 'Version '
        + versionIdx
        + (versionData.version_publication ? ' '+versionData.version_publication : '');
    let versionTitle = versionData.version_title || genericVersionTitle;
    if (versionData.version_lang) versionTitle += ' ('+versionData.version_lang+')';
    if (versionData.version_default) versionTitle += ' (DEFAULT)';
    const tooltip = (versionData.version_description ? versionData.version_description : '')
                  + versionData.version_default;
    const version = {
        record: versionData,
        config: {
            type:'version',
            onclick: function() {
                controlInstance.$emit('open-version', {
                    eventId: controlInstance.eventId,
                    versionId: versionData.version_id
                });
            }
        }
    };
    treeParent.push(version);
    if (versionData.child_versions && versionData.child_versions.length) {
        version.children = [];
        versionData.child_versions.forEach(function(childVersionData, childVersionIdx) {
            versionData2tree(controlInstance, version.children, versionIdx+'-'+(childVersionIdx+1), childVersionData);
        });
    }
}

function getEmptyData() {
    return {
        event_start_date:'',
        event_end_date:'',
        event_duration:'',
        event_text_quality:'',
        event_accuracy:''
    };
}

</script>
