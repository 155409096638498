<!--
Wrapper for CmpCtrlMedium to be used as RouterView, which gets mediumId from routerData.
-->
<template>
  <div class="medium">
    <cmp-ctrl-medium
      :medium-id="mediumId"
    ></cmp-ctrl-medium>
  </div>    
</template>
<style>
</style>
<script>
import CmpCtrlMedium from '@/controls/CmpCtrlMedium.vue';
export default {
    data: function() {
        return  {
            mediumId: ''
        };
    },
    created: function() {
        this.mediumId = this.$route.params.id && this.$route.params.id.length ? this.$route.params.id.substr(1) : '';
    },
    components: {
        cmpCtrlMedium: CmpCtrlMedium
    }
}
</script>
