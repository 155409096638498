import { render, staticRenderFns } from "./CmpMessage.vue?vue&type=template&id=68891496"
import script from "./CmpMessage.vue?vue&type=script&lang=js"
export * from "./CmpMessage.vue?vue&type=script&lang=js"
import style0 from "./CmpMessage.vue?vue&type=style&index=0&id=68891496&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports