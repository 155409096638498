<template>
    <div>
        <b-modal id="modal" size="lg" 
                :title=modalTitle 
                :ok-variant=modalOkVariant 
                :ok-title=modalOkTitle 
                @ok="ok" 
                @cancel="cancel"
                @hide="cancel"
                 >
                 <form>
                <b-container>
                    <b-form-row>
                        <b-col cols="2">
                            <label>Event ID</label>
                            <input type="text" class="form-control" v-model="formData.medium_event_id" v-dir-validation="validationMessages.medium_event_id">
                        </b-col>
                        <b-col cols="2">
                            <label>Version ID</label>
                            <input type="text" class="form-control" v-model="formData.medium_version_id" v-dir-validation="validationMessages.medium_version_id">
                        </b-col>
                        <b-col cols="6">
                            <label>Source File</label>
                            <input type="text" class="form-control" v-model="formData.medium_source_file" placeholder="" v-dir-validation="validationMessages.medium_source_file">
                        </b-col>
                        <b-col cols="2">
                            <label>Source File date</label>
                            <input type="text" class="form-control" v-model="formData.medium_source_file_date_modified" placeholder="" v-dir-validation="validationMessages.medium_source_file_date_modified">
                        </b-col>
                    </b-form-row> 
                    <br />   
                    <b-form-row>
                        <b-col cols="12">
                            <label>Admin information</label>
                            <input type="text" class="form-control" v-model="formData.medium_admin_info" v-dir-validation="validationMessages.medium_admin_info">
                        </b-col>
                    </b-form-row> 
                    <br />
                    <b-form-row>
                        <b-col cols="1">
                            <label>Sorting Nr.:</label>
                            <input type="text" class="form-control" v-model="formData.medium_order_nr" v-dir-validation="validationMessages.medium_order_nr">
                        </b-col>
                    
                        <b-col cols="9">
                            <label>Title (e.g. "Chapter 1 : Our Father")</label>
                            <input type="text" class="form-control" v-model="formData.medium_title" placeholder="Publication Titel/Where Published/Year etc." v-dir-validation="validationMessages.medium_title">
                        </b-col>
                        <b-col cols="1">
                            <label>Data type:</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.medium_data_type"  v-dir-validation="validationMessages.medium_data_type">
                                <option v-for="quality in data_type" :key="quality.code" :value="quality.code">{{quality.title}}</option>
                            </select>
                        </b-col>
                        <b-col cols="1">
                            <label>Language</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.medium_lang" v-dir-validation="validationMessages.medium_lang">
                                <option v-for="(item,index) in languages" :key="index">{{item}}</option>
                            </select>
                        </b-col>
                    </b-form-row>
                    <br />  
                     
                    <b-form-row>
                        <b-col cols="6">
                            <br />
                            <button @click="deleteRecord($event)" class="btn btn-danger" >Delete edition</button>
                        </b-col>
                        <b-col cols="3">
                        <span v-html="this.recordDate"></span>    
                        </b-col>
                        <b-col cols="1">
                            <br />
                            <button @click="cancel($event)" class="btn btn-secondary" >cancel</button>
                        </b-col>
                        <b-col cols="2">
                            <br />
                            <button @click="ok($event)" class="btn btn-primary" >Update media</button>
                        </b-col>
                    </b-form-row>
                    <br />  
                     
                    <b-form-row>

                        <b-col cols="12">
                            <label>Medium URL (or paste Medium Text in second text array below. BUT NOT BOUTH!</label>
                            <input type="text" class="form-control" v-model="formData.medium_url" v-dir-validation="validationMessages.medium_url">
                        </b-col>
                        <b-col cols="12">
                            <label>Medium Text (MarkDown Format)</label>
                            <mavon-editor :toolbars="markdownOption" :language="markdownLanguage" v-model="formData.medium_text" v-dir-validation="validationMessages.medium_text">
                                <!--
                                <template slot="left-toolbar-before">
                                    <button
                                    type="button"
                                    @click="MdBtn1($event)"
                                    class="op-icon fa fa-mavon-align-left"
                                    aria-hidden="true"
                                    title="custom"
                                    ></button>
                                </template>
                                <template slot="left-toolbar-before">
                                    <button
                                    type="button"
                                    @click="$clicks('footnote')"
                                    class="op-icon vage-align-left"
                                    aria-hidden="true"
                                    title="custom"
                                    ></button>
                                </template>
                            -->
                            </mavon-editor>
                            <!--
                                    <b-form-textarea
                                id="textarea"
                                v-model="formData.medium_text"
                                placeholder="text in HTML Format"
                                rows="100"
                                max-rows="1000"
                                ></b-form-textarea>
                            -->
                        </b-col>    
                    </b-form-row>    
                    
                </b-container>
            </form>
        </b-modal>
    </div>
</template>

<script>
import {serverUrl} from '@/configuration';
import SrvMessages from '../../global/SrvMessages';
import DirValidation from '../../controls/DirValidation';
import axios from 'axios';
var $http = require('axios');

export default {
    props: {
        mediumId: Number
    },
    data(){
        return {
            modalTitle:`Edit MEDIUM id: ${this.mediumId}`,
            modalOkTitle:"Update medium",
            modalOkVariant:"primary",
            formData:{
                medium_id: 0,
                medium_data_type: "text",
                medium_source_file: "example file",
                medium_source_file_date_modified:"0000-12-12",
                medium_event_id: this.eventId,
                medium_version_id: this.versionId,
                medium_order_nr:"99",
                medium_title: "Title example",
                medium_text: "<H1>Test title</H1>  <b>Test text</b> ",
                medium_url: "http://test.url.de",
                medium_lang: "deu",
                medium_admin_info: "test admin info",
            },
            
            markdownOption: {
                    bold: true,
                    italic: true,
                    header: true,
                    underline: true,
                    strikethrough: true,
                    mark: true,
                    superscript: true,
                    subscript: true,
                    quote: true,
                    ol: true,
                    ul: true,
                    link: true,
                    imagelink: true,
                    code: true,
                    table: true,
                    fullscreen: true,
                    readmodel: true,
                    htmlcode: true,
                    help: true,
                    /* 1.3.5 */
                    undo: true,
                    redo: true,
                    trash: true,
                    save: true,
                    /* 1.4.2 */
                    navigation: true,
                    /* 2.1.8 */
                    alignleft: true,
                    aligncenter: true,
                    alignright: true,
                    /* 2.2.1 */
                    subfield: true,
                    preview: true
                },
            
            markdownLanguage: "en",
            languages: ['deu','eng','spa','hun'],
            
            data_type: [
                    {code: 'text', title: 'Text'},
                    {code: 'image', title: 'Bild'},
                    {code: 'audio', title: 'Audio'},
                    {code: 'video', title: 'Video'},
                        ],
            validationMessages: {
/*
                medium_event_id                 : 'test 1',
                medium_version_id               : 'test 2',
                medium_source_file              : 'test 3',
                medium_source_file_date_modified: 'test 4',
                medium_admin_info               : 'test 5',
                medium_order_nr                 : 'test 6',
                medium_title                    : 'test 7',
                medium_lang                     : 'test 8',
                medium_url                      : 'test 9',
                medium_text                     : 'test 10'
*/
            }
        }
    },
    computed: {
    // a computed getter
    recordDate() {
        var text = ""
        if (typeof this.formData.medium_create_datetime !== 'undefined') {
            text = "<b>Created:</b>&nbsp;&nbsp;"
            text += this.formData.medium_create_datetime.substring(0, 10) + ' ' + this.formData.medium_create_datetime.substring(11, 19)
            text += "<br /><b>Updated:</b>&nbsp;"
            text += this.formData.medium_update_datetime.substring(0, 10) + ' '+ this.formData.medium_update_datetime.substring(11, 19)
        }
        return text
        }
    },


    mounted(){
        //this.modalTitle = this.modalTitle +': Id ' + this.mediumId;
        this.showRecord()
        this.$bvModal.show('modal');
        
    },

    methods:{
        async showRecord(){
            await $http.get(serverUrl+`/api/medium/${this.mediumId}`, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.formData = response.data
                //console.log(this.formData)
                this.formData._method = "patch" //for laravel update PUT/PATCH hier only post
                //this.getVersions();
            }).catch(error=>{
                SrvMessages.error(error);
            })
        },
        /*
        MdBtn1(e){
            e.fontSize="24px";

            console.log('stisknuto');
            var $vm = this;
            console.log(e);
            var MavonEditor = window.MavonEditor;
            const _this = this;
            _this.$refs;
            var md = this.$refs.md;
            console.log(md);
        },

        $clicks(_type) {
                // 让父节点来绑定事件并
                console.log(_type);
                    this.$emit('toolbar_left_click', _type);
                
            },
        */
        //update Medium
        async ok(e){
            e.preventDefault();
            this.validationMessages = {};
            await $http.post(serverUrl+`/api/medium/`+ this.mediumId,this.formData, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.$emit('messageFromChild', true) //re-write parent
            }).catch(error=>{
                if (error && error.response && error.response.data && error.response.data.errors) {
                    this.validationMessages = {};
                    for (const key in error.response.data.errors)
                        this.validationMessages[key] = error.response.data.errors[key].join('\n');
                } else
                    SrvMessages.error(error);
            })
        },
        cancel(e){
            e.preventDefault();
            console.log(e.trigger);
            //if(e.trigger!='ok' && e.trigger!='esc'){this.$emit('messageFromChild', false);} //re-write false
            if(e.trigger!='ok'){this.$emit('messageFromChild', false);} //re-write false
        }
        ,
        deleteRecord(e){
            e.preventDefault()
            this.$confirm("Are you sure to delete this Medium ?", "Delete medium", "warning")
                .then(() => {
                    $http.delete(serverUrl +`/api/medium/`+ this.mediumId, {
                        headers: {
                        Authorization: 'Bearer '+this.$store.state.sessionToken
                        }
                        }).then(response=>{
                            this.$emit('messageFromChild', true) //re-write parent
                        }).catch(error=>{
                            SrvMessages.error(error);
                        })
                });
        },
    },
    directives: {
        DirValidation: DirValidation
    }
}




</script>

