<!-- 
After registration, the user gets an email with a verification link on /emailVerify
This link shows the following page.
http://192.168.1.10:8080/emailVerify?hash=hunde&id=katze
http://192.168.1.10:8080/emailVerify?id=10&hash=bc30de6dd0c745a3d3dbc756f779715bcff9d7a1
-->
<template>
  <div class="email-verification">
    <h1>Email Verification </h1>
    <div v-if="ok" class="d-flex align-items-center justify-content-center">
      <div class="display-1">&#x1F60A;</div>
      <div class="ml-5">
        <p>The email address has been verificated and your registration is finished successfully.</p>
        <p>Maybe the administrator has to grant you further rights for the required texts</p>
        Go to
        <router-link to="/login"><button class="btn btn-primary btn-sm">Login</button></router-link>
      </div>
    </div>
    <div v-if="!ok" class="d-flex align-items-center justify-content-center">
      <div class="display-1">&#x1F615;</div>
      <div class="ml-5">
        <p>Something went wrong, the email address could not be verificated.</p>
        <p>Please contact the administrator.</p>
        <p><i>{{message}}</i></p>
      </div>
    </div>
  </div>    
</template>
<style>
</style>
<script>
import SrvServerAccess from '../global/SrvServerAccess';
import SrvMessages from '../global/SrvMessages';
export default {
    data: function() {
        return  {
            ok: true,
            message: ''
        };
    },
    mounted: function() {
        const urlParams = new URLSearchParams(window.location.search);
        const hash = urlParams.get('hash') || '';
        const id   = urlParams.get('id'  ) || '';

        const that = this;
        const url = '/api/emailVerify?hash=' + encodeURIComponent(hash) +'&id=' + encodeURIComponent(id);
        SrvServerAccess.request('GET',url, null, true).then(
            function(serverResponse) {
                that.ok = true;
            },
            // Error
            function(error) {
                that.ok = false;
                if (error.toString().indexOf('401')>=0)
                    that.message = 'Error: invalid data';
                else 
                    that.message = error.toString();
        });
    }
}
</script>


