<!--
This is a DIV in oder to show Daniels edit views as overlay
-->
<template>
    <div class="overlayForDanielsViews" v-bind:style="{display:showSomething?'':'none'}" >
        <div class="overlayForDanielsViewsCloser" @click="close(false)" title="Close">&#x2715;</div>
        <EventEdit       v-if="visibleView=='EventEdit'"       :record-id="eventId"        @messageFromChild="close($event)" />
        <EventAdd        v-if="visibleView=='EventAdd'"                                    @messageFromChild="close($event)" />
        <VersionEdit     v-if="visibleView=='VersionEdit'"     :record-id="versionId"      @messageFromChild="close($event)" />
        <VersionAdd      v-if="visibleView=='VersionAdd'"      :record-id="eventId"        @messageFromChild="close($event)" />
        <MediumEdit      v-if="visibleView=='MediumEdit'"      :medium-id="mediumId"       @messageFromChild="close($event)" />
        <MediumAdd       v-if="visibleView=='MediumAdd'"  :event-id="eventId" :version-id="versionId" @messageFromChild="close($event)" />
        <TranslationEdit v-if="visibleView=='TranslationEdit'" :record-id="translationId" @messageFromChild="close($event)" />
        <TranslationAdd  v-if="visibleView=='TranslationAdd'" :record-id="eventId" :default-lang="defaultLang" @messageFromChild="close($event)" />
    </div>
</template>
<style>
    .overlayForDanielsViews {
        position: absolute;
        top:58px;
        bottom:0px;
        left:0px;
        right:0px;
        background-color: #dddddd;
    }
    .overlayForDanielsViewsCloser {
        position: absolute;
        top:10px;
        right:10px;
        cursor: pointer;
        font-weight:700;
    }
</style>
<script>
import EventEdit from '@/views/daniel/EventEdit.vue';
import EventAdd from '@/views/daniel/EventAdd.vue';
import VersionAdd from '@/views/daniel/VersionAdd.vue';
import VersionEdit from '@/views/daniel/VersionEdit.vue';
import MediumAdd from '@/views/daniel/MediumAdd.vue';
import MediumEdit from '@/views/daniel/MediumEdit.vue';
import TranslationAdd from '@/views/daniel/TranslationAdd.vue';
import TranslationEdit from '@/views/daniel/TranslationEdit.vue';
export default {
    props: [
        'eventId',       // Id for an event
        'versionId',     // Id for a  version
        'mediumId',      // Id for a medium
        'translationId', // Id for a translation
        'defaultLang',   // only for translation-add
        'visibleView'    // name of Daniels control to be embedded
        // emit close(somethingChanged)  somethingChanged: true=es wurde etwas geaendert
    ],
    computed: {
        showSomething: function() {
            switch(this.visibleView) {
                case 'EventEdit':       return true;
                case 'EventAdd':        return true;
                case 'VersionAdd':      return true;
                case 'VersionEdit':     return true;
                case 'MediumAdd':       return true;
                case 'MediumEdit':      return true;
                case 'TranslationAdd':  return true;
                case 'TranslationEdit': return true;
                case 'default':         return false;
            }
        }
    },
    methods: {
        close:function(somethingChanged) {
            this.$emit('close', somethingChanged);
        }
    },
    components: {
        EventAdd:        EventAdd,
        EventEdit:       EventEdit,
        VersionAdd:      VersionAdd,
        VersionEdit:     VersionEdit,
        MediumAdd:       MediumAdd,
        MediumEdit:      MediumEdit,
        TranslationAdd:  TranslationAdd,
        TranslationEdit: TranslationEdit
    }
}
</script>
