<template>
    <div>
        <b-modal id="modal" size="lg" 
                :title=modalTitle 
                :ok-variant=modalOkVariant 
                :ok-title=modalOkTitle 
                @ok="ok" 
                @cancel="cancel"
                @hide="cancel"
                >
            <form>
                <b-container class="bv-example-row">
                    <b-form-row>
                        <b-col cols="3">
                            <label>Start Day</label>
                            <input type="text" class="form-control" v-model="formData.event_start_date" placeholder="yyyy-mm-dd">
                        </b-col>
                        <b-col cols="1">
                            <button type="button" @click="copyDate()" class="btn btn-secondary">Copy Date ==></button>
                        </b-col>
                        <b-col cols="3">
                            <label>End Day</label>
                            <input type="text" class="form-control" v-model="formData.event_end_date" placeholder="yyyy-mm-dd">
                        </b-col>
                        <b-col cols="1">
                            <button type="button" @click="countDuration()" class="btn btn-secondary">Count Duration ==></button>
                        </b-col>
                        <b-col cols="2">
                            <label>Duration(days)</label>
                            <input type="text" class="form-control" v-model="formData.event_duration">
                        </b-col>
                        <b-col cols="2">
                            <label>Accuracy</label>
                            <b-form-select  v-model="formData.event_accuracy" :options="accuracy"></b-form-select>
                        </b-col>
                    </b-form-row>
                    <b-form-row>    
                        <b-col>
                            <label>Event UID</label>
                            <a href="https://docs.google.com/document/d/1gEysg__lWK9LNZemJFsJyAHrFTEaYRt0ZbBGDuoetmI/edit#bookmark=id.18ybkn7d3g1y" target="_blank"> ?</a>
                            <input type="text" class="form-control" v-model="formData.event_uid">
                        </b-col>
                        <b-col>
                            <label>Admin Keywords</label>
                        <input type="text" class="form-control" v-model="formData.event_admin_keywords">
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col cols="10">
                            <label>Json Attributes</label>
                            <input type="text" class="form-control" v-model="formData.event_json_attributes">
                        </b-col>  
                        <b-col cols="2">
                            <br />
                            <button @click="deleteRecord($event)" class="btn btn-danger" >Delete Event</button>
                        </b-col>  
                    </b-form-row>
                    
                </b-container>
            </form>
        </b-modal>
    </div>
</template>

<script>
import {serverUrl} from '@/configuration';
import SrvMessages from '../../global/SrvMessages';
import axios from 'axios';
var $http = require('axios');

export default {
    props: {
        recordId: Number
    },
    data(){
        return {
            modalTitle:"Edit Event",
            modalOkTitle:"Save Event",
            modalOkVariant:"primary",
            working:false,
            formData:{
                event_uid:"",
                event_start_date:"",
                event_end_date:"",
                event_accuracy:"day",
                event_duration:1,
                event_admin_keywords:"",
                event_json_attributes:"",
                event_transl_title:"",
                event_transl_lang:"deu",    
            },
            accuracy: [
                    { value: 'day', text: 'Day'},
                    { value: 'week', text: 'Week' },
                    { value: 'month', text: 'Month' },
                    { value: 'season', text: 'Season' },
                    { value: 'year', text: 'Year'}
                    ],
        }
    },
    mounted(){
        this.modalTitle = this.modalTitle +': Id ' + this.recordId;
        this.showRecord()
        this.$bvModal.show('modal');
    },
    methods:{
        copyDate(){
            this.formData.event_end_date=this.formData.event_start_date;
            
        },
        countDuration() {
            var startDateStr = this.formData.event_start_date;
            var endDateStr = this.formData.event_end_date;
            const date1Str = new Date(startDateStr);
            const date2Str = new Date(endDateStr);
            const diffTime = Math.abs(date2Str - date1Str);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            this.formData.event_duration = diffDays;
        },
        async showRecord(){
            await $http.get(serverUrl+`/api/event/${this.recordId}`, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.formData = response.data
                //console.log(this.formData)
                this.formData._method = "patch" //for laravel update PUT/PATCH hier only post
            }).catch(error=>{
                SrvMessages.error(error);
            })
        },
        //update Event
        async ok(e){
            e.preventDefault();
            await $http.post(serverUrl+`/api/event/`+ this.recordId,this.formData, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                //this.$bvModal.hide('modal');
                this.$emit('messageFromChild', true) //re-write parent
                //this.$router.push({path:`/eventSearch/${this.$route.params.id}`})
            }).catch(error=>{
                SrvMessages.error(error);
            })
        },
        cancel(e){
            e.preventDefault();
            if(e.trigger!='ok'){this.$emit('messageFromChild', false); } //re-write false
            //this.$bvModal.hide('modal');
        },
        deleteRecord(e){
            e.preventDefault()
//            if (this.$confirm("Are you sure to delete this Event and all his translations ?", "Delete Event", "warning").then(() => {
            if (confirm('Are you sure to delete this Event and all his translations ?')) {
                $http.delete(serverUrl +`/api/event/${id}`, {
                    headers: {
                        Authorization: 'Bearer '+this.$store.state.sessionToken
                    }
                }).then(response=>{
                        this.$emit('messageFromChild', true) //re-write parent
                        //this.$bvModal.hide('modal');
                    }).catch(error=>{
                        SrvMessages.error(error);
                    })
//            });
            }
        }
    }
}




</script>