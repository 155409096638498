<!--
View to reset password.
The link has been crated by "forgot password"
-->
<template>
    <div class="forgot-password">
        <div class="row justify-content-center align-items-center">
            <div class="col-md-6">
                <div class="col-md-12">
                    <h3 class="text-center">Passwort zurücksetzen</h3>
                    <label for="reset-password-email">Email:</label><br>
                    <input v-model="email" type="text" class="form-control" disabled>
                    <b-alert v-if="emailError" show variant="danger">{{emailError}}</b-alert>
                    <br/>
                    <label for="reset-password">neues Passwort:</label><br>
                    <input placeholder="password at least 8 characters" v-model="password" type="password" id="reset-password" class="form-control">
                    <b-alert v-if="passwordError" show variant="danger">{{passwordError}}</b-alert>
                    <label for="reset-password-confirm">Passwort wiederholen:</label><br>
                    <input placeholder="confirm password" v-model="passwordConfirm" type="password" id="reset-password-confirm" class="form-control">
                    <b-alert v-if="passwordConfirmError" show variant="danger">{{passwordConfirmError}}</b-alert>
                    <input type="button" v-on:click="setupPassword" class="btn btn-secondary" value="Passwort zurücksetzen">
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>

import SrvServerAccess from '../global/SrvServerAccess';
import SrvMessages from '../global/SrvMessages';


export default {
    data: function() {
        return {
            email: this.$route?.query?.email,
            token: this.$route?.query?.token,
            password: '',
            passwordError: '',
            passwordConfirm: '',
            passwordConfirmError: ''
        };
    },
    methods: {
        setupPassword: function() {
            if (!this.email || !this.token) // page has been opened with an invalid url
                return;
            this.passwordError = '';
            this.passwordConfirmError = '';
            if (! this.password)
                this.passwordError = 'Bitte ein Passwort angeben';
//            if (this.password!=this.passwordConfirm)
//                this.passwordConfirmError = 'Die beiden Passwörter stimmen nicht überein';
            if (this.passwordError || this.passwordConfirmeError)
                return;

            const that = this;
            SrvServerAccess.request('POST','/api/resetPassword', {
                    email:                 this.email,
                    token:                 this.token,
                    password:              this.password,
                    password_confirmation: this.passwordConfirm
            }, true).then(
                // Success
                function(serverResponse) {
                    if (serverResponse && serverResponse.data && serverResponse.data.message)
                        SrvMessages.info(serverResponse.data.message);
                    else
                        SrvMessages.info('Passwort erfolgreich geändert.');
                    setTimeout(function(){
                        that.$router.push('/login').catch(function(){});                        
                    },11000);
                },
                // Error
                function(error) {
                    that.passwordError = array2string(error?.response?.data?.errors?.password);
                    const errortext =  array2string(error?.response?.data?.message)
                                    || 'Ein Fehler ist aufgetreten, das Passwort wurde nicht gesetzt.';
                    SrvMessages.error(errortext);

                    function array2string(array) {
                        if (!array2string)                return '';
                        else if (array instanceof Array)  return array.join('\n');
                        else                              return array;
                    }
                }
            );
        }
    }
}               
</script>

