<!--
View for the use case "forgot password"
-->
<template>
    <div class="forgot-password">
        <div class="row justify-content-center align-items-center">
            <div class="col-md-6">
                <div class="col-md-12">
                    <h3 class="text-center">Passwort zurücksetzen</h3>
                    <label for="forgot-password-email">Email:</label><br>
                    <input placeholder="email" v-model="email" type="text" id="forgot-password-email" class="form-control">
                    <b-alert v-if="emailError" show variant="danger">{{emailError}}</b-alert>
                    <input type="button" v-on:click="preparePasswordInput" class="btn btn-secondary" value="Passwort zurücksetzen">
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>

import SrvServerAccess from '../global/SrvServerAccess';
import SrvMessages from '../global/SrvMessages';


export default {
    data: function() {
        return {
            email: '',
            emailError: ''
        };
    },
    methods: {
        preparePasswordInput: function() {
            this.emailError = '';
            if (! this.email) {
                this.emailError = 'Bitte eine gültige Email-Adresse angeben';
                return;
            }
            const that = this;
            SrvServerAccess.request('POST','/api/forgotPassword', {
                    email: this.email
            }, true).then(
                // Success
                function(serverResponse) {
                    if (serverResponse && serverResponse.data && serverResponse.data.message)
                        SrvMessages.info(serverResponse.data.message);
                    else
                        SrvMessages.info('Wir haben Ihnen eine Email mit einem Link zugeschickt.');
                    setTimeout(function(){
                        that.$router.push('/login').catch(function(){});                        
                    },11000);
                },
                // Error
                function(error) {
                    if (  error.response.data && error.response.data.errors 
                        &&(  error.response.data.errors.name
                           ||error.response.data.errors.email
                           ||error.response.data.errors.password)) {
                        if (error.response.data.errors.name instanceof Array)
                            that.userNameError = error.response.data.errors.name.join('\n');
                        if (error.response.data.errors.email instanceof Array)
                            that.emailError = error.response.data.errors.email.join('\n');
                        if (error.response.data.errors.password instanceof Array)
                              that.passwordError = error.response.data.errors.password.join('\n');
                    } else {
                        let message = error.message ? error.message : error;
                        while(message && (typeof(message)=='object'))
                            for(let key in message) {
                                message = message[key];
                                break;
                            }
                        SrvMessages.error(message);
                    }
                }
            );
        }
    }
}               
</script>

