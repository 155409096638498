<template>
    <div>
        <b-modal id="modal" size="lg" 
                :title=modalTitle 
                :ok-variant=modalOkVariant 
                :ok-title=modalOkTitle 
                @ok="ok" 
                @cancel="cancel"
                @hide="cancel"
                 >
            <form>
                <b-container>
                    <b-form-row>
                        <b-col cols="8">
                            <label>Title</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_title" placeholder="Title">
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="8">
                            <label>Subtitle</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_subtitle" placeholder="Subtitle">
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="3">
                            <label>Event Abbreviation</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_abbrev" placeholder="Abbrev.">
                        </b-col>
                        
                        <b-col cols="2">
                            <label>Language</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.event_transl_lang">
                                <option v-for="(item,index) in languages" :key="index">{{item}}</option>
                            </select>
                        </b-col>
                        <b-col cols="2">
                            <label>Original language</label><br />
                            <input type="checkbox"  v-model="formData.event_transl_lang_orig"/>
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="8">
                            <label>Notes</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_notes">
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="8">
                            <label>Attributes JSON</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_attributes">
                        </b-col>
                    </b-form-row>    
                    <b-form-row>
                        <b-col cols="8">
                            <label>Admin Keywords</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_admin_keywords">
                        </b-col>
                    </b-form-row>
                </b-container>
            </form>
        </b-modal>
    </div>
</template>

<script>
import {serverUrl} from '@/configuration';
import SrvMessages from '../../global/SrvMessages';
import axios from 'axios';
var $http = require('axios');

export default {
    props: {
        recordId: Number,
        defaultLang: String
    },
    data(){
        return {
            modalTitle:`Add new translation to event ${this.recordId}`,
            modalOkTitle:"Save new Translation",
            modalOkVariant:"primary",
            formData:{
                event_transl_event_id: this.recordId ,
                event_transl_title: "",
                event_transl_subtitle: "",
                event_transl_abbrev: "",
                event_transl_notes: "",
                event_transl_lang: this.defaultLang || '',
                event_transl_lang_orig: false,
                event_transl_admin_keywords: "",
                event_transl_attributes: ""
            },
            languages: ['deu','eng','spa','hun'],
            
        }
    },

    mounted(){
        this.$bvModal.show('modal');
    },
    methods:{
        async ok(e){
            e.preventDefault();
            //console.log(this.formData);
            await $http.post(serverUrl+'/api/translation',this.formData, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.$emit('messageFromChild', true); //revrite true
                
            }).catch(error=>{
                SrvMessages.error(error);
            })
            
        },

        cancel(e){
            e.preventDefault();
            if(e.trigger!='ok'){this.$emit('messageFromChild', false);} //re-write false
        }
        
    }
}
</script>