<!--
this is a component in order to display a message.
usualy it is hidden, but there is a service, to activate it.
The Component listens on the service SrvMessages in order to get messages to display.
-->
<template>
    <div>
        <b-modal id="detailDialog" title="Error Details" class="modal fade messageDetailDialog" hide-footer>
            <div class="h-100 modal-dialog modal-full">
                <div class="h-100 modal-content">
                    <div v-if="message.message"><span class="messageDetailTitle">message: </span>{{message.message}}</div>
                    <div v-if="message.exception"><span class="messageDetailTitle">exception: </span>{{message.exception}}</div>
                    <div v-if="message.url"><span class="messageDetailTitle">url: </span>{{message.url}}</div>
                    <div v-if="message.method"><span class="messageDetailTitle">method: </span>{{message.method}}</div>
                    <div v-if="message.data"><span class="messageDetailTitle">data: </span>{{messageData}}</div>
                    <div v-if="message.file"><span class="messageDetailTitle">file: </span>{{message.file}}</div>
                    <div v-if="message.number"><span class="messageDetailTitle">line: </span>{{message.line}}</div>
                    <div v-for="(key,value) in message.errors">
                        <b>{{key}}:</b> {{value}}
                    </div>
                    <div if="messageValidations.length" class="messageValidations">
                        <b>validations:</b><br/>
                        <div v-for="(item) in messageValidations">
                            <b>{{item.key}}:</b> {{item.msg}}
                        </div>
                    </div>
                    <div v-if="message.trace && message.trace.length">
                        <div><b>Trace:</b></div>
                        <div v-for="record in message.trace">
                            <b>{{record.file}}</b>
                            {{record.line}}
                            <span v-if="record.function">&nbsp;Function: <i>{{record.function}}</i></span>
                            <span v-if="record.class">&nbsp;Class: <i>{{record.class}}</i></span>
                            <span v-if="record.type">&nbsp;Type: <i>{{record.type}}</i></span>
                        </div>
                    </div>
                    <div if="messageStack.length" class="messageDetailStackOuter">
                        <b>stack:</b><br/>
                        <div class="messageDetailStackInner">
                            <i v-for="(line) in messageStack">{{line}}</i><br/>
                        </div>
                    </div>
                    <button @click="closeDetails">CLOSE</button>
                </div>
            </div>
        </b-modal>
        <b-alert dismissible
            @dismiss-count-down="setCountdown"
            :show="countdown"
            :variant="color"
            class="messageAlert"
        >{{messageText}} 
            <span v-if="showDetailIcon" title="details" @click="openDetails" class="messageDetailIcon">&#x1F4AC;</span>
            <b-progress
                :variant="color"
                :max="ALERT_SECONDS"
                :value="countdown"
                height="4px"
            ></b-progress>  
        </b-alert>
    </div>
</template>
<style>
    .messageAlert {
        z-index:1042;
    }
    span.messageDetailIcon {
        cursor: pointer;
    }
    .messageDetailDialog {
        z-index:1043;
    }
    .messageDetailTitle {
        font-size:0.8em;
        font-weight:700;
    }
    .messageDetailStackOuter {
        overflow:scroll;
    }
    .messageDetailStackInner {
        width:1200px;
        font-size: 0.8em;
    }
</style>
<script>
import SrvMessages from '../../global/SrvMessages';
export default {
    data: function() {
        return {
            ALERT_SECONDS: 10,
            message: '',
            type: 'info',
            color: '',
            countdown: 0
        };
    },
    computed: {
        showDetailIcon: function() {
            return this.message && (typeof(this.message)=='object');
        },
        // message can be a string or an object.
        // a full blown message object looks like this,
        // but usualy only some properties are set.
        // message = {
        //     message: sting,
        //     errors: {key: string},
        //     exception: string,
        //     file: string,
        //     line: number,
        //     trace: [{
        //         file: string,
        //         line: number,
        //         function: string
        //         class: string
        //         type: string
        //     }],
        //     url: string,
        //     method: string,
        //     data: JSON-string,
        //     stack: string with many lines
        // }
        messageText: function() {
            if (!this.message)
                return 'no message';
            if (typeof(this.message) == 'string')
                return this.message;
            if (this.message.message)
                return this.message.message;
            if (this.message.exception)
                return this.message.exception;
            if (this.message.errors) {
                const lines = [];
                for (const key in this.message.errors)
                    lines.push(this.message.errors[key]);
                return lines.join('\n');
            }
        },
        messageData: function() {
            if (!this.message || (typeof(this.message)!= 'object') || (!this.message.data))
                return '';
            try {
                return eval('('+this.message.data+')');
            } catch(err) {
                return this.message.data;
            }
        },
        messageStack: function() {
            if (!this.message || (typeof(this.message)!= 'object') || (!this.message.stack))
                return [];
            return this.message.stack.split('\n');
        },
        messageValidations: function() {
            let validations = [];
            if (this.message && this.message.response && this.message.response.data && this.message.response.data.errors)
                for (const key in this.message.response.data.errors)
                    validations.push({key: key, msg: this.message.response.data.errors[key].join('\n')});
            return validations;
        }
    },
    created: function() {
        const that = this;
        SrvMessages.addListener(function(message) {
            if (that.countdown) { // a previous message is still visible
                const LEVELS = ['info','warn','err'];
                const levelOldMsg = LEVELS.indexOf(that.type);
                const levelNewMsg = LEVELS.indexOf(message.type);
                if (levelOldMsg>levelNewMsg) // an error is visible and would be overwritten by a simple message
                    return;
            }
            that.countdown = that.ALERT_SECONDS;
            that.message = message.message;
            that.type = message.type;
            switch(message.type) {
                case 'err':  that.color = 'danger';  break;
                case 'warn': that.color = 'warning'; break;
                default:
                case 'info': that.color = 'success'; break;
            }
        });
    },
    methods: {
        openDetails() {
            this.$bvModal.show('detailDialog');
        },
        closeDetails() {
            this.$bvModal.hide('detailDialog');
        },
        setCountdown: function(val) {
            this.countdown = val;
        }
    }
}
</script>

    