<!--
Wrapper for CmpCtrlVersion to be used as RouterView, which gets eventId from routerData.
-->
<template>
  <div class="version">
    <cmp-ctrl-version
      :event-id="eventId"
      :version-id="versionId"
      @open-text="openTextOverlay"
    ></cmp-ctrl-version>
    <div class="versionOverlay" v-bind:style="{display:mediumId4Overlay?'':'none'}" >
        <div class="versionOverlayCloser" @click="closeOverlay"title="Close">&#x2715;</div>
        <cmp-ctrl-medium
            v-if="mediumId4Overlay"
            :medium-id="mediumId4Overlay"
            :show-link-button="true"
        ></cmp-ctrl-medium>
    </div>
  </div>    
</template>
<style>
    .versionOverlay {
        position: absolute;
        top:58px;
        bottom:0px;
        left:0px;
        right:0px;
        background-color: #dddddd;
    }
    .versionOverlayCloser {
        position: absolute;
        top:10px;
        right:10px;
        cursor: pointer;
        font-weight:700;
    }
</style>
<script>
import CmpCtrlVersion from '@/controls/CmpCtrlVersion.vue';
import CmpCtrlMedium from '@/controls/CmpCtrlMedium.vue';
export default {
    data: function() {
        return  {
            eventId: '',
            versionId: '',
            mediumId4Overlay: ''
        };
    },
    created: function() {
        const idEventAndVersion = this.$route.params.idEventAndVersion.length ? this.$route.params.idEventAndVersion.substr(1) : '';
        const parts = idEventAndVersion.split('-');
        this.eventId = parts.shift();
        this.versionId = parts.shift();
    },
    components: {
        CmpCtrlVersion: CmpCtrlVersion,
        CmpCtrlMedium: CmpCtrlMedium
    },
    methods: {
        openTextOverlay: function(mediumId) {
            this.mediumId4Overlay = mediumId;
        },
        closeOverlay: function() {
            this.mediumId4Overlay = '';
            window.scrollTo(0, 0);
        }
    }
}
</script>
