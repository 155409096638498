<!--
View for user login
-->
<template>
  <div>
    <div>
        <div class="row justify-content-center align-items-center">
            <div class="col-md-6">
                <div class="col-md-12">
                    <h3 class="text-center">Login</h3>
                    <label for="login-email">Username or Email:</label><br>
                    <input :disabled="notVerificated" @keyup="onUserEnter" v-bind:class="{ loginIncorrect: isLoginIncorrect }" v-model="usernameOrEmail" type="text" id="login-email" class="form-control">
                    <label for="login-password">Password:</label><br>
                    <input :disabled="notVerificated" ref="passwordInput" @keyup="onPasswortEnter" v-bind:class="{ loginIncorrect: isLoginIncorrect }" v-model="password" type="password" id="login-password" class="form-control">
                    <span>Remember me</span> <span><input :disabled="notVerificated" v-model="useLocalStorage" type="checkbox"></span><br/>
                    <input v-if="!notVerificated" type="button" v-on:click="tryLogin" class="btn btn-secondary" value="Login">
                    <input v-if="notVerificated" type="button" v-on:click="sendVerificationEmail" class="btn btn-secondary" value="Send Verification Email">
                    <div v-if="!notVerificated" class="text-right">
                        <a class="nav-link" v-on:click="register" href="#">Registrieren</a>
                    </div>
                    <div v-if="!notVerificated" class="text-right">
                        <a class="nav-link" v-on:click="forgotPassword" href="#">Passwort vergessen</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<style>
  input.loginIncorrect {
    background-color: #ffcccc;
  }
</style>
<script>
import SrvAuthentification from '../global/SrvAuthentification';
import SrvMessages from '../global/SrvMessages';

export default {
    data: function() {
        return {
            usernameOrEmail: '',
            password: '',
            isLoginIncorrect: false,
            useLocalStorage: false, // flag for "remember me"
            notVerificated: false   // flag for "the email is not yet verificated
        };
    },
    created: function() {
    },
    methods: {
        tryLogin: function () {
            const that = this;
            SrvAuthentification.login(this.usernameOrEmail, this.password, this.useLocalStorage)
            .then(function(bearerToken) {
                if (bearerToken) {
                    // User is authenticated.
                    // If originaly the user entered another route than /login
                    // we redirect to this id. The default ist /search
                    const routeParts = that.$route.fullPath.split('/'); // /login  oder  /login/my/original/path
                    if (!routeParts.shift()) //  "" for /login...   "login" for login....
                        routeParts.shift(); //  [] or [my,original,pat]
                    let newRoute = '/' + (routeParts.join('/') || 'search');
                    that.$router.push(newRoute).catch(function(){});
                }
                else
                    that.isLoginIncorrect = true;
            })
            .catch(function(error) {
                if (error && (typeof(error)=='object') && error.notVerificated)
                    that.notVerificated = true;
                else
                    that.isLoginIncorrect = true;
            });
        },
        sendVerificationEmail: async function() {
            let result = null;
            try {
                // login once more with flag "send verification email"
                await SrvAuthentification.login(this.usernameOrEmail, this.password, false, true);
            } catch(ex) {
                if (ex && (typeof(ex)=='object') && ex.notVerificated)
                    SrvMessages.warn('Please check you emails and click to the verification link there.');
                else
                    SrvMessages.error(ex);
            }            
        },
        // the bearerToken may be stored within sessionStorage or localStorage (i.e. after F5 or after setting "remember me")
        // then, a new login is not necessary, but we must reload the userdata, which are lost after F5...
        register: function () {
            this.$router.push('/register').catch(function(){});
        },
        forgotPassword: function () {
            this.$router.push('/forgotPassword').catch(function(){});
        },
        onUserEnter: function(evt) {
            if (evt.key=='Enter')
                this.$refs.passwordInput.focus();
        },
        onPasswortEnter: function(evt) {
            if (evt.key=='Enter')
                this.tryLogin();
        }
    }
}               
</script>

