<!--
One search result block for an event
-->
<template>
    <div class="text-left" v-bind:class="{searchResultBlockBright: index%2,searchResultBlockDark: !(index%2)}">
        <cmp-ctrl-event-small :record="eventFromServer" v-on:click="toggleEventDetail" />
        <div class="searchResultBlockShortText">
            <span v-html="record.medium_text_short"></span>
            <cmp-ctrl-medium-small v-if="record && record.medium_id" :record="record" :config="{small:true,onclick:directoOpenText}"/>
        </div>
       <Transition>
            <div v-if="isEventExpanded">
                <cmp-ctrl-event
                    :event-id="eventFromServer.event_id"
                    show-link-button="true"
                    @open-medium="$emit('open-medium', $event)"
                    @open-comments="$emit('open-comments', $event)"
                    @open-version="$emit('open-version', $event)"
                    @edit-event="$emit('edit-event', $event)"
                    @add-version="$emit('add-version', $event)"
                    @edit-version="$emit('edit-version', $event)"
                    @add-medium="$emit('add-medium', $event)"
                    @edit-translation="$emit('edit-translation', $event)"
                    @add-translation="$emit('add-translation', $event)"
                >
                </cmp-ctrl-event>
            </div>
        </Transition>
    </div>
</template>
<style>
    .searchResultBlockBright {
        background-color: #cfcfcf;
    }
    .searchResultBlockDark {
        background-color: #c0c0c0;
    }
    div.searchResultBlockShortText {
        font-size: 0.7em;
        padding-bottom:10px;
    }
    span.term-1 {background-color: rgb(148, 238, 148); }
    span.term-2 {background-color: rgb(228, 200, 74); }
    span.term-3 {background-color: rgb(91, 190, 214); }
    span.term-4 {background-color: rgb(238, 144, 144); }
    span.term-5 {background-color: rgb(126, 151, 133); }
    span.term-6 {background-color: rgb(247, 170, 228); }
    span.term-7 {background-color: rgb(140, 128, 241); }
    span.term-8 {background-color: rgb(243, 196, 134); }
    span.term-9 {background-color: rgb(124, 175, 128); }
    span.term-10 {background-color: rgb(131, 74, 74); }
</style>
<script>
// @ is an alias to /src
import CmpCtrlEvent from '@/controls/CmpCtrlEvent.vue'
import CmpCtrlEventSmall from '@/controls/CmpCtrlEventSmall.vue';
import CmpCtrlMediumSmall from '@/controls/CmpCtrlMediumSmall.vue';

export default {
    props: [
        'record', // if full text search is given:
                  // { event: {
                  //       event_accuracy:...,
                  //       event_duration:...,
                  //       event_end_date:...,
                  //       event_id:...,
                  //       event_start_date:...  }
                  //   medium_id:...,
                  //   medium_event_id:...,
                  //   medium_title:...,
                  //   medium_lang:...,
                  //   version: {
                  //       version_id:...,
                  //       version_parent_id:...,
                  //       version_title:...,
                  //       version_lang:...  }
                  // }
                  //
                  // only with title search:
                  // { event_accuracy:...,
                  //   event_duration:...,
                  //   event_end_date:...,
                  //   event_id:...,
                  //   event_start_date:...  }
                  // }
        'index'   // Index of this result (>=0)
        // $emit: 
        //   open-medium      with mediumId after click on a medium icon
        //   open-version     with {eventId:...,versionId:...} after click on a medium icon
        //   edit-event       with eventId after click on edit-event icon
        //   edit-translation with translationId after click on edit-translation icon within event
        //   add-translation  with eventId after click on add-translation icon within event
    ],
    data: function() {
        return {
            isEventExpanded: false,
            textExpandedMap: {} // {textId:true}
        };
    },
    computed: {
        // false: only with title search
        // true:  full text search is given
        isFullTextBlock: function() {
            return !!this.record.medium_id;
        },
        eventFromServer: function() {
            if (!this.record)
                return {};
            return this.isFullTextBlock 
                 ? (this.record.event ? this.record.event : {}) 
                 : this.record;
        }
    },
    methods: {
        toggleEventDetail: function() {
            this.isEventExpanded = ! this.isEventExpanded;
        },
        directoOpenText: function() {
            // nur wenn wir Texte laden (=Volltext), nicht wenn wir Events laden (=nur Titelsuche)
            this.$emit('open-medium', this.record.medium_id);
        }
    },
    components: {
        CmpCtrlEvent:       CmpCtrlEvent,
        CmpCtrlEventSmall:  CmpCtrlEventSmall,
        CmpCtrlMediumSmall: CmpCtrlMediumSmall
    }
}
</script>
