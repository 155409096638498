<!--
This is a DIV in oder to render a medium, a version, an event, or some comments as overlay
-->
<template>
    <div class="overlayForNestedData" v-bind:style="{display:showSomething?'':'none'}" >
        <div class="overlayForNestedDataCloser" @click="$emit('close')"title="Close">&#x2715;</div>
        <cmp-ctrl-medium
            v-if="type=='medium'"
            :show-link-button="true"
            :medium-id="mediumId"
            :highlighted="highlighted"
            @edit-medium="function($mediumId) { $emit('edit-medium',$mediumId)}"
        ></cmp-ctrl-medium>
        <cmp-ctrl-event
            v-if="type=='event'"
            :event-id="eventId"
            :show-link-button="true"
            @open-version="function($evtVersData) { $emit('open-version',$evtVersData)}"
            @open-medium="function($mediumId) { $emit('open-medium',$mediumId)}"
            @open-comments="function($eventId) { $emit('open-comments',$eventId)}"
        ></cmp-ctrl-event>
        <cmp-ctrl-version
            v-if="type=='version'"
            :version-id="versionId"
            :event-id="eventId"
            :show-link-button="true"
            @open-version="function($evtVersData) { $emit('open-version',$evtVersData)}"
            @open-medium="function($mediumId) { $emit('open-medium',$mediumId)}"
            @edit-version="function($evtVersData) { $emit('edit-version',$evtVersData)}"
            @edit-medium="function($mediumId) { $emit('edit-medium',$mediumId)}"
            @add-medium="function($evtVersData) { $emit('add-medium',$evtVersData)}"
        ></cmp-ctrl-version>
        <cmp-ctrl-comments
            v-if="type=='comments'"
            :event-id="eventId"
        ></cmp-ctrl-comments>
    </div>
</template>
<style>
    .overlayForNestedData {
        position: absolute;
        top:58px;
        bottom:0px;
        left:0px;
        right:0px;
        background-color: #dddddd;
    }
    .overlayForNestedDataCloser {
        position: absolute;
        top:10px;
        right:10px;
        cursor: pointer;
        font-weight:700;
    }
</style>
<script>
import CmpCtrlMedium from '@/controls/CmpCtrlMedium'
import CmpCtrlEvent from '@/controls/CmpCtrlEvent';
import CmpCtrlVersion from '@/controls/CmpCtrlVersion';
import CmpCtrlComments from '@/controls/CmpCtrlComments';
export default {
    props: [
        'medium-id',    // Id for a medium to show
        'event-id',     // Id for an event to show
        'version-id',   // Id for a version to show (event-id must also be given)
        'type',         // one of event, medium, version, comments or ''
        'highlighted'   // content of fulltext search field in order to highlight within text
        // emit close()
    ],
    computed: {
        showSomething: function() {
            switch(this.type) {
                case 'event':
                    return this.eventId;
                case 'version':
                    return this.versionId && this.eventId;
                case 'medium':
                    return this.mediumId;
                case 'comments':
                    return this.eventId;
                case 'default':
                    return false;
            }
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyDown);
    },
    beforeDestroy: function() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
//        openVersion: function(newVersionId) {
//            alert(newVersionId);
//        }
        handleKeyDown: function(event) {
            if (event.keyCode === 27)
                this.$emit('close');
        }
    },
    components: {
        cmpCtrlMedium: CmpCtrlMedium,
        cmpCtrlEvent: CmpCtrlEvent,
        cmpCtrlVersion: CmpCtrlVersion,
        CmpCtrlComments: CmpCtrlComments
    }
}
</script>
