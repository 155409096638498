// this Service can be used to broadcast a message from any point in the code.
// usualy the CmpMessage Component listens to this service and shows the message.

const listener = [];

export default {
    addListener: function(newListener) {
        listener.push(newListener);
    },
    removeListener: function(theListener) {
        listener.splice(listener.indexOf(theListener), 1);
    },
    error: function(message) {
        callListeners('err', makeJsonIfNecessary(message));
    },
    warn: function(message) {
        callListeners('warn', makeJsonIfNecessary(message));
    },
    info: function(message) {
        callListeners('info', makeJsonIfNecessary(message));
    }
}

function makeJsonIfNecessary(messageObject) {
    if (!messageObject)
        return '';
    if (typeof(messageObject)!='object')
        return messageObject;
    if (messageObject.response && (messageObject.response.status==422))
        // das ist eine unuebersichtliche Angelegenheit:
        // bei 500-Fehlern war es uns dienlich, toJSON() aufzurufen
        // bei 422-Fehlern (Validation) geht dadurch aber das wesentliche verloren
        // return messageObject;

        // Stand 30.10.2024
        // messageObject ist das, was in promise.catch(function(error)  geliefert wird.
        // wir muessen dort explizit error.response  ansprechen, sonst kriegen wir unbrauchbaren Wert.
        return (messageObject && messageObject.response && messageObject.response.data && messageObject.response.data.errors) 
             ? JSON.stringify(messageObject.response.data.errors)
             : messageObject;
    if (messageObject.toJSON)
        return messageObject.toJSON();
    else
        return messageObject;
}

function callListeners(type, message) {
    listener.forEach(function(curListener) {
        curListener({type:type, message:message});
    });
}

function getMessage(error) {
    // error: {
    //     message: sting,
    //     errors: {key: string},
    //     exception: string,
    //     file: string,
    //     line: number,
    //     trace: [{
    //         file: string,
    //         line: number,
    //         function: string
    //         class: string
    //         type: string
    //     }]
    // }
    if (!error)
        return 'no message';
    if (typeof(error) == 'string')
        return error;
    if (error.message)
        return error.message;
    if (error.exception)
        return error.exception;
    if (error.errors) {
        const lines = [];
        for (const key in error.errors)
            lines.push(error.errors[key]);
        return lines.join('\n');
    }
}

/*
zufallsmeldung();
function zufallsmeldung() {
    setTimeout(function() {
        callListener();
        zufallsmeldung();
    }, 2000+5000*Math.random());
}

let i = 1;
function callListener() {
    const type = ['err','warn','info'][Math.floor(3*Math.random())];
    callListeners(type, 'Message '+(i++));
}
*/