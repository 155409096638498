<!--
A control for a date picker:
A simple text field and an interpreter for user input.
As soon, as we need more own controls or as soon, as this control is used within another view,
we will move the file to a global controls directory.
-->
<template>
    <span class="ctrlDatepicker">
        <input v-model="userinput" v-on:blur="parseUserInput()" placeholder="tt.mm.jjjj-tt.mm.jjjj"  @keyup="keyUp" />
    </span>
</template>
<style>
    span.ctrlDatepicker {}
</style>
<script>
export default {
    props: [
        'fromDate',         // yyyy-mm-dd
        'toDate',           // yyyy-mm-dd
        'min',              // yyyy-mm-dd 
        'max'               // yyyy-mm-dd 
        // emit: enter
    ],
    data: function() {
        return {
            userinput: ''
        };
    },
    watch: {
        fromDate: function(newVal) {
            this.formatData();
        },
        toDate: function(newVal) {
            this.formatData();
        }
    },
    created: function() {
        this.parseUserInput();  
    },
    methods: {
        emitEnterEvent: function() {
            this.parseUserInput();
            this.$emit('enter');
        },
        keyUp: function(evt) {
            if (evt.key=='Enter')
                this.emitEnterEvent();
        },
        parseUserInput: function() {
            // parsing
            var from = '';
            var to = '';
            if (this.userinput.indexOf('<')>=0)
                to = this.userinput.split('<')[1];
            else  if (this.userinput.indexOf('>')>=0)
                from = this.userinput.split('>')[1];
            else  if (this.userinput.indexOf('-')>=0) {
                var aFromToSplit = this.userinput.split('-');
                from = aFromToSplit[0];
                to = aFromToSplit[1];
            } else {
                from = this.userinput;
                to   = this.userinput;
            }
            from = date2sqlFormat(from, false);
            to   = date2sqlFormat(to, true);
            
            // validation
            if (this.max) {
                if (to  && (to  >this.max)) to   = this.max;
                if (from&& (from>this.max)) from = this.max;
            }
            if (this.min) {
                if (to  && (to  <this.min)) to   = this.min;
                if (from&& (from<this.min)) from = this.min;
            }
            if (from && to && to<from)
                to=from;

            if ((this.fromDate != from) || (this.toDate != to))
                this.$emit('datechanged', {from:from,to:to});

            // 18.10.1914 --> 1914-10-18
            // 10.1914    --> 1914-10
            // 1914       --> 1914
            // xy         --> ''
            function date2sqlFormat(date, prefereEndDate) {
                date = date ? date.trim() : '';
                if (! date) return '';
                var aParts = date.split('.');
                var year, month, day;
                for (var i=0; i<aParts.length; i++)
                    if (isNaN(aParts[i]))
                        return '';
                var isLastDayOfFeb = false;
                switch(aParts.length) {
                    case 1:
                        year = 1*aParts[0];
                        month = prefereEndDate ? 12 : 1;
                        day = prefereEndDate ? 31 : 1;
                        break;
                    case 2:
                        year = 1*aParts[1];
                        month = 1*aParts[0];
                        day = 1;
                        if (prefereEndDate) {
                                  //  1  2  3  4  5  6  7  8  9 10 11 12
                            day = [0,31,28,31,30,31,30,31,31,30,31,30,31][month];
                            isLastDayOfFeb = (month==2);
                        }
                        break;
                    case 3:
                    default:
                        year = 1*aParts[2];
                        month = 1*aParts[1];
                        day = 1*aParts[0];
                        break;
                }
                if (year<100)
                    year+=1900;
                if (year>=10000)  return '';
                if (  isLastDayOfFeb
                    &&(year%4==0)   // leap year
                    &&((year%100>0)||(year%400==0)))
                    day=29;
                
                // let javascript validate the date
                var date = new Date();
                date.setTime(0);
                date.setHours(12); // middle of the day
                date.setFullYear(year);
                date.setMonth(month-1);
                date.setDate(day);
                
                year = date.getFullYear();
                month = date.getMonth()+1;
                day = date.getDate();
                var ret = ''+year;
                if (month) ret += '-'+makeTwoChar(month);
                if (day)   ret += '-'+makeTwoChar(day);
                return ret;
            }
            
            function makeTwoChar(number) {
                if (number<10) return '0'+number;
                else           return ''+number;
            }
//            function oneDayEarlier(date) {
//                var time = date.getTime();
//                return new Date(time-86400000);
//            }
        },
        formatData: function() {
            if (!this.toDate && !this.fromDate) {
                this.userinput = '';
                return;
            }
            if ((this.fromDate<=this.min) && (this.toDate>=this.max)) {
                this.userinput = '';
                return;
            }
            else if (!this.toDate || (this.toDate>=this.max))
                this.userinput = '>'+dateSql2german(this.fromDate);
            else if (!this.fromDate || (this.fromDate<=this.min))
                this.userinput = '<'+dateSql2german(this.toDate);
            else if (this.fromDate == this.toDate)
                this.userinput = dateSql2german(this.toDate);
            else
                this.userinput = dateSql2german(this.fromDate)+'-'+dateSql2german(this.toDate);

            // 1914-10-18 --> 18.10.1914
            // 1914-10    --> 01.10.1914
            // 1914       --> 01.01.1914
            // ''         --> ''
            function dateSql2german(date) {
                if (!date) return '';
                if (date.length>=10)
                    return date.substr(8,2)+'.'+date.substr(5,2)+'.'+date.substr(0,4);
                else if (date.length>=7)
                    return '01.'+date.substr(5,2)+'.'+date.substr(0,4);
                else
                    return '01.01.'+date.substr(0,4);
            }
        }
    }
}
</script>
