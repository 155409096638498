<!--
a flag icon for a language
-->
<template>
  <span
    :title="languageName"
    v-on:click="click"
    :style="{cursor:(clickable ? 'pointer' : 'default')}"
  >
    <img class="crlLanguageIcon" :src="languageFlag" :style="{opacity: (semiTransparent&&(semiTransparent!='false')) ? '0.4' : '1'}"/>
  </span>
</template>
<style>
    .crlLanguageIcon {
        border: 1px solid #aaaaaa;
    }
</style>
<script>

export default {
    props: [
        'lang', // deu, spa, ung, eng, ...
        'semi-transparent', // true: less opacity
        'clickable' // true: you can click on it
        // $emit:  click(lang)
    ],
    data: function() {
        return  {
        };
    },
    computed: {
        languageName: function() {
            switch(this.lang) {
                case 'deu': return 'deutsch';
                case 'eng': return 'englisch';
                case 'hun': return 'ungarisch';
                case 'spa': return 'spanisch';
                default:    return this.lang;
            }
        },
        // languageFlagAsUtf: function() {
        //     switch(this.lang) {
        //         case 'deu': return '&#x1f1e9;';
        //         case 'eng': return '&#x1f1ec;';
        //         case 'hun': return '&#x1f1ed;';
        //         default:    return lang;
        //     }
        // },
        languageFlag: function() {
            let icon = '';
            switch(this.lang) {
                case 'deu': icon = 'deu.png';     break;
                case 'eng': icon = 'eng.png';     break;
                case 'hun': icon = 'hun.png';     break;
                case 'spa': icon = 'spa.png';     break;
                default:    icon = 'unknown.png'; break;
            }
            return require('@/assets/'+icon);
        }
    },
    methods: {
        click: function() {
            if (this.clickable) {
                this.$emit('click', this.lang);
            }
        }
    }
}
</script>
