<!--
Display many keywords
-->
<template>
  <div class="ctrlKeywords">
    <span class="ctrlKeywordsTitle">Keywords</span>
    <span v-for="keyword in keywordsToShow" v-html="keyword" class="ctrlKeywordsKeyword"/>
  </div>    
</template>
<style>
    div.ctrlKeywords {
    }
    span.ctrlKeywordsTitle {
        font-weight: 700;
        font-size: 0.7em;
    }
    span.ctrlKeywordsKeyword {
        font-weight: 700;
        font-size: 0.7em;
        background-color:#ffffff;
        margin: 3px;
        padding: 1px;
        border: 1px solid #000000;
        border-radius: 3px;
    }
</style>
<script>
export default {
    props: [
        'keywords'    // [{id:..., key_keyword: 'MyKeyword'}], ...}]
    ],
    computed: {
        keywordsToShow: function() {
            const ret = [];
            if (this.keywords)
                this.keywords.forEach(function(record) {
                    ret.push(record.key_keyword);
                });
            return ret;
        }
    }
}
</script>
