<template>
    <div class="container">
        <button type="button" v-if="controlState=='loggedIn'" v-on:click="logout" class="btn btn-secondary">Logout</button>
        <button type="button" v-if="controlState=='showLoginBtn'" v-on:click="gotoLoginView" class="btn btn-secondary">Login</button>
        <span v-if="controlState=='loginViewActive'" class="text-nowrap">please login</span>
    </div>
</template>
<script>

import SrvAuthentification from '../../global/SrvAuthentification';

export default {
    data: function() {
        return {
            controlState: 'showLoginBtn'
        };    
    },
    created: function() {
        const that = this;
        SrvAuthentification.addListener(function(data) {
            that.onUserDataChanged(data);
        });
    }, 
    destroyed: function() {
        SrvAuthentification.removeListener(this.onUserDataChanged);
    },
    methods: {
        logout: function() {
            const that = this;
            SrvAuthentification.logout().then(function() {
                that.$router.push('/login').catch(function(){});
            });
        },
        gotoLoginView: function() {
            this.$router.push('/login').catch(function(){});
        },
        onUserDataChanged: function(userData) {
            if (userData.sessionToken)
                this.controlState = 'loggedIn';
            else
                this.controlState = 'showLoginBtn';
        }
    },
}
</script>

    