<template>
    <div>
        <b-modal id="modal" size="lg" 
                :title=modalTitle 
                :ok-variant=modalOkVariant 
                :ok-title=modalOkTitle 
                @ok="ok" 
                @cancel="cancel"
                @hide="cancel"
                >
            <form>
                <b-container class="bv-example-row">
                    <b-form-row>
                        <b-col cols="2">
                            <span>
                                id:<br /> 
                                user: <br />
                                email: <br />
                                email verified at:<br />
                            </span>
                            <div>
                                roles:
                            </div>
                            

                        </b-col>
                        <b-col cols="4">
                            <span>
                                {{ user.id}}<br />
                                {{ user.name}}<br />
                                {{ user.email}}<br />
                                {{ user.email_verified_at}}<br />
                            </span> 
                            <div v-for="role in roles" :key="role" >
                                <li>{{role}}</li>
                            </div>
                        </b-col>
                        <b-col cols="4">
                            <label>First name</label>
                            <input type="text" class="form-control" v-model="formData.FirstName">
                            <label>Last name</label>
                            <input type="text" class="form-control" v-model="formData.LastName">
                            <label>Full name</label>
                            <input type="text" class="form-control" v-model="formData.FullName">
                            
                            <label>User Interface Language:</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.LangUI">
                                <option v-for="(item,index) in languages" :key="index">{{item}}</option>
                            </select>
                            <label>Search languages</label>
                            <input type="text" class="form-control" v-model="formData.LangSearch">
                            <label>Show languages</label>
                            <input type="text" class="form-control" v-model="formData.LangShow">
                            <label>Show languages limit (max. count)</label>
                            <input type="text" class="form-control" v-model="formData.LangShowLimit">
                            <label>Results per page</label>
                            <input type="text" class="form-control" v-model="formData.ResultsPerPage">
                            <label>Default Results Only</label>
                            <input type="checkbox" class="form-control" v-model="formData.DefaultResultsOnly">
                            
                        </b-col>
                        
                        
                    </b-form-row>
                    
                    
                </b-container>
            </form>
        </b-modal>
    </div>
</template>

<script>
import {serverUrl} from '@/configuration';
import SrvMessages from '../../global/SrvMessages';
import axios from 'axios';
var $http = require('axios');

export default {
    props: {
        recordId: Number
    },
    data(){
        return {
            modalTitle:"Edit User Settings",
            modalOkTitle:"Save Setting",
            modalOkVariant:"primary",
            working:false,
            formData:{
                FirstName: "",
                LastName: "",
                FullName: "",
                LangUI: "eng",
                LangSearch: "deu,eng",
                LangShow: "deu,esp",
                LangShowLimit: "10",
                ResultsPerPage: "5",
                DefaultResultsOnly: true
            },
            user:{},
            roles:[],
            languages: ['deu','eng','spa'],
        }
    },
    mounted(){
        //this.modalTitle = this.modalTitle +': Id ' + this.recordId;
        this.showRecord()
        this.$bvModal.show('modal');
    },
    methods:{
        async showRecord(){
            await $http.get(serverUrl+`/api/me`, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.formData = response.data.settings;
                this.user = response.data.user;
                this.roles = response.data.roles;
            }).catch(error=>{
                SrvMessages.error(error);
            })
        },
        //update Event
        async ok(e){
            e.preventDefault();
            await $http.post(serverUrl+'/api/updateUserSettings',this.formData, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                var that = this;
                $http.get(serverUrl+'/api/me', {
                    useCredentails: true,
                    'Access-Control-Allow-Origin': '*',
                    crossdomain: true,
                    headers: {
                        Authorization: 'Bearer '+that.$store.state.sessionToken
                    }
                }
            ).then(
                // Success
                function(serverResponse) {
                    delete serverResponse.data.token; // already stored in $store.state.sessionToken
                    that.$store.commit('setUserData', serverResponse.data);
                }
            ).catch(function(error) {
                that.$store.commit('setUserData', {
                    user: {
                        name: 'error while loading user data'
                    }
                });
            });
                this.$emit('messageFromChild', true) //re-write parent
            }).catch(error=>{
                SrvMessages.error(error);
            })
        },
        cancel(e){
            e.preventDefault();
            if(e.trigger!='ok'){this.$emit('messageFromChild', false); } //re-write false
            //this.$bvModal.hide('modal');
        },
        reloadUserData() {
            console.log('reload');
            var that = this;
            $http.get(serverUrl+'/api/me', {
                    useCredentails: true,
                    'Access-Control-Allow-Origin': '*',
                    crossdomain: true,
                    headers: {
                        Authorization: 'Bearer '+that.$store.state.sessionToken
                    }
                }
            ).then(
                // Success
                function(serverResponse) {
                    delete serverResponse.data.token; // already stored in $store.state.sessionToken
                    that.$store.commit('setUserData', serverResponse.data);
                }
            ).catch(function(error) {
                that.$store.commit('setUserData', {
                    user: {
                        name: 'error while loading user data'
                    }
                });
            });
        },
    }
}




</script>