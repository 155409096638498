<template>
    <div>
        <b-modal id="modal" size="lg" 
                :title=modalTitle 
                :ok-variant=modalOkVariant 
                :ok-title=modalOkTitle 
                @ok="ok" 
                @cancel="cancel"
                @hide="cancel"
                 >
            <form>
                <b-container class="bv-example-row">
                    <b-form-row>
                        <b-col cols="3">
                            <label>Start Day</label>
                            <input type="text" class="form-control" v-model="formData.event_start_date" placeholder="yyyy-mm-dd">
                        </b-col>
                        <b-col cols="1">
                            <button type="button" @click="copyDate()" class="btn btn-secondary">Copy Date ==></button>
                        </b-col>
                        <b-col cols="3">
                            <label>End Day</label>
                            <input type="text" class="form-control" v-model="formData.event_end_date" placeholder="yyyy-mm-dd">
                        </b-col>
                        <b-col cols="1">
                            <button type="button" @click="countDuration()" class="btn btn-secondary">Count Duration ==></button>
                        </b-col>
                        <b-col cols="2">
                            <label>Duration(days)</label>
                            <input type="text" class="form-control" v-model="formData.event_duration">
                        </b-col>
                        <b-col cols="2">
                            <label>Accuracy</label>
                            <b-form-select  v-model="formData.event_accuracy" :options="accuracy"></b-form-select>
                        </b-col>
                    </b-form-row>
                    <b-form-row>    
                        <b-col>
                            <label>Event UID</label>
                            <a href="https://docs.google.com/document/d/1gEysg__lWK9LNZemJFsJyAHrFTEaYRt0ZbBGDuoetmI/edit#bookmark=id.18ybkn7d3g1y" target="_blank"> ?</a>
                            <input type="text" class="form-control" v-model="formData.event_uid">
                        </b-col>
                        <b-col>
                            <label>Admin Keywords</label>
                        <input type="text" class="form-control" v-model="formData.event_admin_keywords">
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col>
                            <label>Json Attributes</label>
                            <input type="text" class="form-control" v-model="formData.event_json_attributes">
                        </b-col>    
                    </b-form-row>
                    <hr/>
                    <b-form-row>
                        <b-col cols="8">
                            <label>Title</label>
                            <input type="text" class="form-control" v-model="formData.event_transl_title">
                        </b-col>
                        <b-col cols="2">
                            <label>Title Language</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="formData.event_transl_lang">
                                <option v-for="(item,index) in languages" :key="index">{{item}}</option>
                            </select>
                        </b-col>
                        <b-col cols="2">
                            <label>Original language</label><br />
                            <input type="checkbox" :value="true"  v-model="formData.event_transl_lang_orig"/>
                        </b-col>
                    </b-form-row>
                </b-container>
            </form>
        </b-modal>
    </div>
</template>

<script>
import {serverUrl} from '@/configuration';
import SrvMessages from '../../global/SrvMessages';
import axios from 'axios';
var $http = require('axios');


export default {
    name:"add-category",
    data(){
        return {
            modalTitle:"Add new even",
            modalOkTitle:"Save new Event",
            modalOkVariant:"primary",
            formData:{
                event_uid:"1910-01-01_",
                event_start_date:"1910-01-01",
                event_end_date:"1910-01-01",
                event_accuracy:"day",
                event_duration:1,
                event_admin_keywords:"",
                event_json_attributes:"",
                event_transl_title:"Borkovec",
                event_transl_lang:"deu",   
                event_transl_lang_orig: true,  
            },
            accuracy: [
                    { value: 'day', text: 'Day'},
                    { value: 'week', text: 'Week' },
                    { value: 'month', text: 'Month' },
                    { value: 'season', text: 'Season' },
                    { value: 'year', text: 'Year'}
                    ],
            languages: ['deu','eng','spa'],
        }
    },

    mounted(){
        this.$bvModal.show('modal');
    },
    methods:{
        copyDate(){
            this.formData.event_end_date=this.formData.event_start_date;
            
        },
        countDuration() {
            var startDateStr = this.formData.event_start_date;
            var endDateStr = this.formData.event_end_date;
            const date1Str = new Date(startDateStr);
            const date2Str = new Date(endDateStr);
            const diffTime = Math.abs(date2Str - date1Str);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            this.formData.event_duration = diffDays;
        },
        
        async ok(e){
            e.preventDefault();
            await $http.post(serverUrl+'/api/event',this.formData, {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.sessionToken
                }
            }).then(response=>{
                this.$emit('messageFromChild', true) //revrite true
            }).catch(error=>{
                SrvMessages.error(error);
            })
        },

        cancel(e){
            this.$emit('messageFromChild', false);//re-write false
        }
        
    }
}
</script>